import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonAlert, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle
} from '@ionic/react';
import './Studentlist.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, addCircleOutline, checkmark, contacts} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import {StaticEvent} from "../_/StaticEvent";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import _ from "../_";
import Moment from "react-moment";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,
  pagePathname: string,
}


class Personaldata extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,
      pagePathname: this.props.location.pathname,
    };

  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    // const id = this.props.match.params.id;
    // if (!id) {
    //   return; //ERROR INPUTS
    // }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      //p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/personaldata');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  render() {
    let Items: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    if (pageData != null) {
      let it = this.state.pageData.Item_會員;
      Items =
        <React.Fragment>
          <IonList lines="inset">
            {/* <IonItem>
              <IonLabel>
                <div className="th">培訓結束後邀約對象情況</div>
                <div className="itemname">
                {it.Is已邀約到一對一對象 === true &&
                  <div className="itgp">

                    <span className="con">已邀約到一對一對象</span>

                  {it.已邀約到一對一對象_信仰狀態 != null &&
                    <span className="con"> : {it.已邀約到一對一對象_信仰狀態}
                    {it.已邀約到一對一對象_信仰狀態 === "其他" &&
                        <span>信仰-{it.已邀約到一對一對象_信仰狀態_其他}</span>
                    }
                    </span>
                  }
                  </div>
                  }
                  {(it.Is已邀約到一對一對象 === true && it.已邀約到一對一對象_預計何時開始一對一 != null) &&
                    <div className="itgp">
                      <span className="con">預計開始時間：</span>
                      <span className="con">{it.已邀約到一對一對象_預計何時開始一對一}</span>
                    </div>
                  }

                  {it.Is已有預備邀約對象 === true &&
                  <div className="itgp">

                    <span className="con">已有預備邀約對象</span>

                  {it.已有預備邀約對象_姓名 != null &&
                    <span className="con"> : {it.已有預備邀約對象_姓名}</span>
                  }
                  </div>
                  }
                  {it.Is需要教會提供對象 === true &&
                  <div className="itgp">
                    <span className="con">需要教會提供對象</span>
                  </div>
                  }
                  {it.Is目前尚不想開始邀約 === true &&
                  <div className="itgp">
                    <span className="con">目前尚不想開始邀約</span>
                    {it.已有預備邀約對象_姓名 != null &&
                    <span className="con">，原因 : {it.目前尚不想開始邀約_原因}</span>
                  }
                  </div>
                  }
                </div>


              </IonLabel>
            </IonItem> */}
            <IonItem>
              <IonLabel>
                <div className="th">接受派工的意願</div>
                {it.派工意願}
                {(it.派工意願 === "暫時無法接受派工" || it.派工意願 === "無法接受派工" ) &&
                    <span> : {it.派工意願_原因}</span>
                }
              </IonLabel>
            </IonItem>
          </IonList>
          {it.派工意願 === "可接受派工" &&
          <div className="recivetimetable">
            <div className="th">派工時段</div>
            <div className="timetable">
              <div className="tb tbheader">
                <div className="tb_th">時間</div>
                <div className="tb_th">一</div>
                <div className="tb_th">二</div>
                <div className="tb_th">三</div>
                <div className="tb_th">四</div>
                <div className="tb_th">五</div>
                <div className="tb_th">六</div>
                <div className="tb_th">日</div>
              </div>
              <div className="tb">
                <div className="tb_td tb_th">上午</div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('一-上午') !== -1 &&
                    <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('二-上午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('三-上午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('四-上午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('五-上午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('六-上午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('日-上午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
              </div>
              <div className="tb">
                <div className="tb_td tb_th">下午</div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('一-下午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('二-下午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('三-下午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('四-下午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('五-下午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('六-下午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('日-下午') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
              </div>
              <div className="tb">
                <div className="tb_td tb_th">晚上</div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('一-晚上') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('二-晚上') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('三-晚上') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('四-晚上') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('五-晚上') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('六-晚上') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
                <div className="tb_td">
                  {_.utilManager.coalesce(it.派工時段組, '').indexOf('日-晚上') !== -1 &&
                  <IonIcon icon={contacts} slot="start" color="success" size="large"/>
                  }
                </div>
              </div>
            </div>
          </div>
          }

          <IonList lines="inset">
            <IonItem>
              <IonLabel>
                <div className="th">姓名</div>
                {it.姓氏}{it.名字}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <div className="th">性別</div>
                {it.性別}
              </IonLabel>
            </IonItem>
            {/*20200116 修改欄位- 生日修改成出生年份*/}
            <IonItem>
              <IonLabel>
                <div className="th">出生年份</div>
                <Moment format="YYYY" date={it.出生年月日}/>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <div className="th">手機</div>
                {it.手機}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <div className="th">Email</div>
                {it.Email}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <div className="th">學歷</div>
                {it.學歷}{it.學歷_其他}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <div className="th">職業</div>
                {it.職業}{it.職業_其他}
              </IonLabel>
            </IonItem>
            {/*20200116 新增欄位*/}
            <IonItem>
              <IonLabel>
                <div className="th">信主年份</div>
                {it.信主年數}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <div className="th">所屬教會</div>
                {it.所屬教會}
              </IonLabel>
            </IonItem>


            <IonItem>
              <IonLabel>
                <div className="th">所屬教會國家城市行政區</div>
                {it.所屬教會國家}{it.所屬教會城市}{it.所屬教會行政區}
              </IonLabel>
            </IonItem>
            {/*20200116 新增欄位*/}
            <IonItem>
              <IonLabel>
                <div className="th">服事職稱</div>
                {it.服事職務}
              </IonLabel>
            </IonItem>
            {/*20200116 新增欄位*/}
            <IonItem>
              <IonLabel>
                <div className="th">區名稱</div>
                {it.區名}
              </IonLabel>
            </IonItem>
            {/*20200116 新增欄位*/}
            <IonItem>
              <IonLabel>
                <div className="th">小組名稱</div>
                {it.團契小組}
              </IonLabel>
            </IonItem>
          </IonList>
        </React.Fragment>;
    }

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            <IonTitle>個人資料及派工意願</IonTitle>
            <IonButtons slot="end">
              {this.state.pageData != null &&
              <IonButton href={`/personaldataedit`} onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push(`/personaldataedit/${this.state.pageData.Item_會員.會員Id}`);
                }}>
                <IonIcon slot="icon-only" src="/assets/icon/pencil.svg" className="pedit"></IonIcon>
              </IonButton>
              }
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="personal-page">
          {Items}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Personaldata);
