import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonTextarea,
  IonCheckbox
} from '@ionic/react';
import './Curriculumsituationinput.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, cloudDownload, addCircleOutline} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,
  Item_對課進程: any,

  shouldLoadPageDataAgain: boolean,

  enableDownload: boolean,
  Default完成對課: boolean | null,
  pagePathname: string,
}

const customActionSheetOptions = {
  header: '請選擇語言版本',
};

class Curriculumsituationinput_date extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,
      Item_對課進程: {},

      shouldLoadPageDataAgain: false,

      Default完成對課: null,
      enableDownload: false,
      pagePathname: this.props.location.pathname,
    };
  }


  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/curriculumsituationinput_date');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            // @ts-ignore
            await this.setState({
              pageData: payload,
              Item_對課進程: payload.Item_對課進程,
              enableDownload: payload.Item_對課進程.上課時間 != null,
              Default完成對課: payload.Item_對課進程.完成對課,
            });
          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  handleInputChange(e) {
    let newItem = Object.assign({}, this.state.Item_對課進程, {
      [e.target.name]: e.target.value,
    });

    // @ts-ignore
    this.setState({
      Item_對課進程: newItem,
    });
  }

  handleInputCheckChange(e) {
    let newItem = Object.assign({}, this.state.Item_對課進程, {
      [e.target.name]: e.target.checked,
    });

    // @ts-ignore
    this.setState({
      Item_對課進程: newItem,
    });
  }


  async handleSaveSubmit(e: any) {
    e.preventDefault();

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: this.state.Item_對課進程,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_curriculumsituationinput_date');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            this.props.history.goBack();


            // let enableDownload = this.state.enableDownload;
            // if (enableDownload === false) {
            //   await this._loadPageData();
            //
            //   // if (this.state.enableDownload) {
            //   //   alert('您現在可以下載逐字稿');
            //   // }
            // } else {
            // }
          } else {
            alert(error.message);
            // const controller: any = document.querySelector('ion-toast-controller');
            // let msg = await controller.create({
            //   message: error.message,
            //   duration: 2000,
            //   position: 'top'
            // });
            // await msg.present();
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  render() {
    let Content: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');
    let selectyear = String((new Date().getFullYear()) + 1);


    if (pageData != null) {
      var it = pageData.Item_對課進程;

      //console.dir(it);
      if (it === null) {
        alert('嚴重錯誤');
        return;
      }

      Content = <>
        <div className="nocontent">
          <div className="noteintro">
            <h4>下載【逐字講稿】規則：</h4>
            <p>請務必填寫“預計對課日期”後，執行右上角的“儲存”，才會顯示下載【逐字講稿】，接著才能下載索取</p>
          </div>
        </div>


        <IonItem>
          <IonIcon icon={calendar} slot="start" color="danger"></IonIcon>
          <IonLabel>預計對課日期</IonLabel>
          <IonDatetime
            name="上課時間"
            value={this.state.Item_對課進程.上課時間}
            onIonChange={(e: any) => this.handleInputChange(e)}

            min="1990-02"
            max={selectyear}
            displayFormat="YYYY/MM/DD" placeholder="請選擇日期" done-text="確定" cancel-text="取消"></IonDatetime>

        </IonItem>

      </>;
    }


    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            {this.state.pageData &&
              <IonTitle>{_.custom.lessionName(it.第幾課)}</IonTitle>
            }
            {this.state.pageData &&
              <IonButtons slot="end">
                <IonButton onClick={e => this.handleSaveSubmit(e)}>
                  儲存
                </IonButton>
              </IonButtons>
            }
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent scroll-y="true">
          {Content}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Curriculumsituationinput_date);
