import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonToast,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonBackButton,
  IonTitle
} from '@ionic/react';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import SessionList from '../components/SessionList';
import SessionListFilter from '../components/SessionListFilter';
import {actions, RootState, selectors} from '../store';
import './Teachingmaterial.css';
import {share, logoVimeo, logoGoogleplus, logoTwitter, logoFacebook, options, cloudDownload} from 'ionicons/icons';

const slideOpts = {
  initialSlide: 1,
  speed: 400
};

type Props = RouteComponentProps<{}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

type State = {
  segment: string;
  isRefreshing: boolean;
  showLoading: boolean;
  showFilterModal: boolean;
  loadingMessage: string;
};

class Teachingmaterial extends Component<Props, State> {
  ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;
  ionFabRef: React.RefObject<HTMLIonFabElement>;
  state = {
    segment: 'all',
    isRefreshing: false,
    showLoading: false,
    showFilterModal: false,
    loadingMessage: ''
  };

  constructor(props: Props) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.ionFabRef = React.createRef<HTMLIonFabElement>();
  }

  presentFilter = () => {
    this.setState(() => ({
      showFilterModal: true
    }));
  };

  updateSearchTerm = (e: CustomEvent) => {
    this.props.setSearchText(e.detail.value);
  };

  openSocial = (network: string) => {
    this.setState(() => ({
      loadingMessage: `Posting to ${network}`,
      showLoading: true
    }));

    setTimeout(() => {
      this.setState(() => ({showLoading: false}));
    }, Math.random() * 1000 + 500);

    if (this.ionFabRef.current) {
      this.ionFabRef.current.close();
    }
  };

  updateSegment = (e: CustomEvent) => {
    this.setState(prevState => ({
      ...prevState,
      segment: e.detail.value
    }));
  };

  doRefresh = () => {
    setTimeout(() => {
      this.setState(() => ({isRefreshing: true}));
      if (this.ionRefresherRef.current) {
        this.ionRefresherRef.current.complete();
      }
    }, 500);
  };

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            <IonTitle>教材下載</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="slideroption">
          <IonList>
            <IonItem button
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>介紹課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第一課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第二課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第三課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第四課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button disabled
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第五課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button disabled
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第六課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button disabled
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第七課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button disabled
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第八課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button disabled
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第九課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
            <IonItem button disabled
                     onClick={() => {
                       this.props.history.push('/teachingmaterialdownload');
                     }}>
              <IonLabel>第十課</IonLabel>
              <IonIcon src="/assets/icon/document.svg" slot="end"></IonIcon>
            </IonItem>
          </IonList>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  allFiltered: selectors.sessions.allFiltered(state.sessions),
  favoritesFiltered: selectors.sessions.favoritesFiltered(state.sessions),
  searchText: state.sessions.searchText,
  favoriteSessions: state.sessions.favoriteSessions,
  filteredTracks: state.sessions.trackFilters,
  allTracks: selectors.sessions.allTracks(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

const mapDispatchToProps = {
  setSearchText: (searchText: string) => actions.sessions.setSearchText(searchText),
  updateTrackFilters: (trackList: string[]) => actions.sessions.updateTrackFilters(trackList)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teachingmaterial);
