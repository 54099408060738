import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PrivateRoute, RequiresTutorialRoute} from './utils/routing';
import AppStack from './pages/AppStack';
import Menu from './components/Menu';

import _ from "./_";
import {createBrowserHistory, createHashHistory} from 'history';

declare var window: any;

let history = createBrowserHistory();
window._ow_history = history;
_.sharedStateManager.history = history;


//DTB ---------------------------------------------------
// import Emailverify from './pages/Emailverify';
// import Devmenu from './pages/Devmenu';
// import Curriculumlist_value from "./pages/Curriculumlist_value";


import {IonApp, IonSplitPane, IonPage, IonReactRouter, IonRouterOutlet, IonTab, IonLoading} from '@ionic/react';
import {Provider} from 'react-redux';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import './theme.css';

import ReactHtmlParser from 'react-html-parser';

import store from './store';

const App = () => (
  <Provider store={store}>
    <IonApp>
      {
        //ReactHtmlParser('<ion-loading-controller></ion-loading-controller>')
      }
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu/>

          <IonPage id="main">
            <Switch>
              <RequiresTutorialRoute path="/" component={AppStack}/>
            </Switch>
          </IonPage>


        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  </Provider>
);

export default App;
