import {
  IonAlert,
  IonLoading,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router';
import {actions, RootState} from '../store';
import About from './About';
import MapView from './Map';
import SchedulePage from './SchedulePage';
import SessionDetail from './SessionDetail';
import SpeakerDetail from './SpeakerDetail';
import SpeakerList from './SpeakerList';
import Emailverify from './Emailverify';
import {calendar, contacts, map, informationCircle} from 'ionicons/icons';
import {PrivateRoute, RequiresTutorialRoute} from "../utils/routing";
import Account from "./Account";
import Tutorial from "./Tutorial";
import Devmenu from "./Devmenu";
import Curriculumlist from "./Curriculumlist";
import Curriculummanagement from "./Curriculummanagement";
import Curriculum from "./Curriculum";
import Curriculumsituationinput from "./Curriculumsituationinput";
import Personalstatistics from "./Personalstatistics";
import Personaldata from "./Personaldata";
import Personaldataedit from "./Personaldataedit";
import Studentlist from "./Studentlist";
import Studentlist_st from "./Studentlist_st";
import Studentdatainputstep1 from "./Studentdatainputstep1";
import Studentdatainputstep2 from "./Studentdatainputstep2";
import Studentdatainputstep3 from "./Studentdatainputstep3";
import Studentmanagement from "./Studentmanagement";
import Studentsituationaftercurriculum from "./Studentsituationaftercurriculum";
import Studentcurriculumstop from "./Studentcurriculumstop";
import Studentdataedit from "./Studentdataedit";
import Teachingmaterial from "./Teachingmaterial";
import Teachingmaterialdownload from "./Teachingmaterialdownload";
import Articlelist from "./Articlelist";
import Articlecontent from "./Articlecontent";
import Articlelist_qptool from "./Articlelist_qptool";
import Sendjob from "./Sendjob";
import Language from "./Language";
import Setting from "./Setting";
import Termsprivacy from "./Termsprivacy";
import QA from "./QA";
import Login from "./Login";
import Support from "./Support";
import Signup from "./Signup";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import Curriculumlist_value from "./Curriculumlist_value";
import {throttleTime, auditTime, debounceTime} from "rxjs/operators";
import Logout from "./Logout";
import Root from "./Root";
import Curriculumsituationinput_date from './Curriculumsituationinput_date';
import Curriculumlist_tutorial from "./Curriculumlist_tutorial";
import Curriculummanagement_tutorial from "./Curriculummanagement_tutorial";
import Studentlist_st_tutorial from "./Studentlist_st_tutorial";
import Curriculum_tutorial from "./Curriculum_tutorial";
import Curriculumsituationinput_tutorial from "./Curriculumsituationinput_tutorial";
import Curriculumsituationinput_tutorial_date from "./Curriculumsituationinput_tutorial_date";
import Teachingmaterialdownload_tutorial from "./Teachingmaterialdownload_tutorial";
import Studentdatainputstep1_tutorial from "./Studentdatainputstep1_tutorial";

type AppStackProps = typeof mapDispatchToProps;

type State = {
  showGlobalLoading: boolean,
  showGlobalLoadingMessage: string,
  //showGlobalLoadingEvent_DidDismiss: any,

  _userData: any,
}

class AppStack extends React.Component<AppStackProps, State> {
  public subscribed = [];

  constructor(props: AppStackProps) {
    super(props);

    props.updateLocations();
    props.updateSessions();
    props.updateSpeakers();

    this.state = {
      //showPopover: false,
      //showPopoverEvent: null,

      showGlobalLoading: false,
      showGlobalLoadingMessage: 'Loading...',
      //showGlobalLoadingEvent_DidDismiss: (e)=>{ this.state.showGlobalLoadingEvent_DidDismiss(e) },
      _userData: null,
    };

    let subject$ = StaticEvent.LoadingEvent$.pipe(debounceTime(2000));

    this.subscribed.push(subject$.subscribe(async (v) => {
      await this.setState({
        showGlobalLoading: v,
      });

      console.warn('test:' + v);
    }));
  }


  async componentDidMount() {
    //await super.componentDidMount();
  }

  async componentWillUnmount() {
    //await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  showGlobalLoadingEvent_DidDismiss(e: any) {
  }

  render() {

    const showGlobalLoading = this.state.showGlobalLoading;
    const showGlobalLoadingMessage = this.state.showGlobalLoadingMessage;


    function randomGen() {
      return Math.random();
    }

    return (
      <IonPage>

        <IonRouterOutlet>
          <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/logout" component={Logout}/>

            <Route exact path="/curriculumlistvalue" component={Curriculumlist_value}/>
            <Route exact path="/emailverify" component={Emailverify}/>

            {/*<RequiresTutorialRoute path="/login" component={Login}/>*/}
            {/*<RequiresTutorialRoute path="/support" component={Support}/>*/}
            {/*<RequiresTutorialRoute path="/signup" component={Signup}/>*/}
            {/*<PrivateRoute path="/account" component={Account}/>*/}


            <Route exact path="/root" component={Root}/>

            <Route exact path="/tutorial" component={Tutorial}/>
            <Route exact path="/emailverify" component={Emailverify}/>
            <Route exact path="/devmenu" component={Devmenu}/>


            <Route exact path="/termsprivacy" component={Termsprivacy}/>

            <Route exact path="/curriculummanagement/:id" component={Curriculummanagement}/>
            <Route exact path="/curriculummanagement_tutorial/:id" component={Curriculummanagement_tutorial}/>

            <Route exact path="/curriculumlist" component={Curriculumlist} key="curriculumlist"/>
            <Route exact path="/curriculumlist_tutorial" component={Curriculumlist_tutorial} key="curriculumlistt"/>

            <Route exact path="/curriculum/:id" component={Curriculum}/>
            <Route exact path="/curriculum_tutorial/:id" component={Curriculum_tutorial}/>

            {/*對課進程Id*/}
            <Route exact path="/curriculumsituationinput/:id" component={Curriculumsituationinput}/>
            <Route exact path="/curriculumsituationinput_tutorial/:id" component={Curriculumsituationinput_tutorial}/>
            <Route exact path="/curriculumsituationinput_date/:id" component={Curriculumsituationinput_date}/>
            <Route exact path="/curriculumsituationinput_tutorial_date/:id" component={Curriculumsituationinput_tutorial_date}/>


            <Route exact path="/personalstatistics" component={Personalstatistics}/>
            <Route exact path="/personaldata" component={Personaldata}/>
            <Route exact path="/personaldataedit/:id" component={Personaldataedit}/>

            <Route exact path="/studentlistst/:id" component={Studentlist_st}/>
            <Route exact path="/studentlistst_tutorial/:id" component={Studentlist_st_tutorial}/>


            <Route exact path="/studentlist/:id" component={Studentlist} />

            <Route exact path="/studentdatainputstep1/:id" component={Studentdatainputstep1}/>
            <Route exact path="/studentdatainputstep1_tutorial/:id" component={Studentdatainputstep1_tutorial}/>

            <Route exact path="/studentdatainputstep2" component={Studentdatainputstep2}/>
            <Route exact path="/studentdatainputstep3" component={Studentdatainputstep3}/>
            <Route exact path="/studentmanagement/:id" component={Studentmanagement}/>

            <Route exact path="/studentsituationaftercurriculum/:id" component={Studentsituationaftercurriculum}/>
            <Route exact path="/studentcurriculumstop/:id" component={Studentcurriculumstop}/>
            <Route exact path="/studentdataedit/:id" component={Studentdataedit}/>

            <Route exact path="/teachingmaterial" component={Teachingmaterial}/>

            <Route exact path="/teachingmaterialdownload/:id" component={Teachingmaterialdownload}/>
            <Route exact path="/teachingmaterialdownload_tutorial/:id" component={Teachingmaterialdownload_tutorial}/>

            <Route exact path="/articlelist" component={Articlelist}/>
            <Route exact path="/articlelist_qptool" component={Articlelist_qptool}/>

            <Route exact path="/articlecontent/:id" component={Articlecontent}/>



            <Route exact path="/sendjob" component={Sendjob}/>
            <Route exact path="/qa" component={QA}/>
            <Route exact path="/language" component={Language}/>
            <Route exact path="/setting" component={Setting}/>


            {/*<Route exact path="/:tab(schedule)" component={SchedulePage}/>*/}
            {/*<Route exact path="/:tab(speakers)" component={SpeakerList}/>*/}
            {/*<Route exact path="/:tab(speakers)/speaker/:id" component={SpeakerDetail}/>*/}
            {/*<Route exact path="/:tab(schedule|speakers)/sessions/:id" component={SessionDetail}/>*/}
            {/*<Route exact path="/:tab(map)" component={MapView}/>*/}
            <Route exact path="/about" component={About}/>

            <Route exact path="/" render={() => <Redirect to="/root"/>}/>
          </Switch>
        </IonRouterOutlet>

        {/*<IonAlert*/}
        {/*  isOpen={true}*/}
        {/*  onDidDismiss={() => this.setShowAlert2()}*/}
        {/*  header={'Alert'}*/}
        {/*  subHeader={'Subtitle'}*/}
        {/*  message={'This is an alert message.'}*/}
        {/*  buttons={['Cancel', 'Open Modal', 'Delete']}*/}
        {/*/>*/}

        <IonLoading
          isOpen={showGlobalLoading}
          onDidDismiss={(e) => this.showGlobalLoadingEvent_DidDismiss(e)}
          message={showGlobalLoadingMessage}
          //duration={5000}
        />


        {/*<IonTabs>*/}
        {/*  <IonTabBar slot="bottom">*/}
        {/*    <IonTabButton tab="schedule" href="/schedule">*/}
        {/*      <IonIcon icon={calendar} />*/}
        {/*      <IonLabel>Schedule</IonLabel>*/}
        {/*    </IonTabButton>*/}
        {/*    <IonTabButton tab="speakers" href="/speakers">*/}
        {/*      <IonIcon icon={contacts} />*/}
        {/*      <IonLabel>Speakers</IonLabel>*/}
        {/*    </IonTabButton>*/}
        {/*    <IonTabButton tab="map" href="/map">*/}
        {/*      <IonIcon icon={map} />*/}
        {/*      <IonLabel>Map</IonLabel>*/}
        {/*    </IonTabButton>*/}
        {/*    <IonTabButton tab="about" href="/about">*/}
        {/*      <IonIcon icon={informationCircle} />*/}
        {/*      <IonLabel>About</IonLabel>*/}
        {/*    </IonTabButton>*/}
        {/*  </IonTabBar>*/}
        {/*</IonTabs>*/}
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  updateLocations: () => actions.locations.updateLocations(),
  updateSessions: () => actions.sessions.updateSessions(),
  updateSpeakers: () => actions.speakers.updateSpeakers()
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppStack);
