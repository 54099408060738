import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonAlert,
  IonItemSliding,
  IonItemOptions,
  IonItemOption
} from '@ionic/react';
import './Curriculumlist.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, addCircleOutline, trash} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,
}


class Curriculumlist_value extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,
    };

  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }


  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.push('/index')} text="返回"/>
            </IonButtons>
            <IonTitle>對課管理</IonTitle>
            <IonButtons slot="end">
              <IonButton icon-only>
                <IonIcon icon={addCircleOutline} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="slideroption">
          <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)} mode="md">
            <IonSegmentButton value="sunny" checked>
              <IonLabel>對課中</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="rainy">
              <IonLabel>對完課</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonList lines="full" className="stulist">
            <IonItemSliding>
              <IonItem button
                       href="/curriculummanagement" onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push('/curriculummanagement');
                }}>
                <IonLabel>
                  <div className="stinfo">
                    <div className="title">輸入的名稱</div>
                    <div className="ptxt">目前進度：課程進度 上課時間</div>
                    <div className="ptxt">對課類型 對象姓名、<span className="stop">已暫停對象姓名</span></div>
                    <div className="info">
                      <span>YYYY/MM/DD</span>
                    </div>
                  </div>

                </IonLabel>
              </IonItem>
              <IonItemOptions side="end">
                <IonItemOption color="danger" expandable>
                  <IonIcon color="light" slot="icon-only" src="/assets/icon/trash.svg"></IonIcon>
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
            <IonItemSliding>
              <IonItem button
                       href="/curriculummanagement" onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push('/curriculummanagement');
                }}>
                <IonLabel>
                  <div className="stinfo">
                    <div className="title">案件1</div>
                    <div className="ptxt">目前進度：未啟動</div>
                    <div className="ptxt">對課對象未建立</div>
                    <div className="info">
                      <span>2019/10/27</span>
                    </div>
                  </div>
                </IonLabel>
              </IonItem>
              <IonItemOptions side="end">
                <IonItemOption color="danger" expandable>
                  <IonIcon color="light" slot="icon-only" src="/assets/icon/trash.svg"></IonIcon>
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
            <IonItemSliding>
              <IonItem button
                       href="/curriculummanagement" onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push('/curriculummanagement');
                }}>
                <IonLabel>
                  <div className="stinfo">
                    <div className="title">案件1</div>
                    <div className="ptxt">目前進度：L2 2019/8/23</div>
                    <div className="ptxt">1對多 李四端、金世昌、Lisa Wang、<span className="otostop">林芯儀</span></div>
                    <div className="info">
                      <span>2019/10/23</span>
                    </div>
                  </div>
                </IonLabel>
              </IonItem>
              <IonItemOptions side="end">
                <IonItemOption color="danger" expandable>
                  <IonIcon color="light" slot="icon-only" src="/assets/icon/trash.svg"></IonIcon>
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
            <IonItemSliding>
              <IonItem button
                       href="/curriculummanagement" onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push('/curriculummanagement');
                }}>
                <IonLabel>
                  <div className="stinfo">
                    <div className="title">案件1</div>
                    <div className="ptxt">目前進度：L5 2019/9/27</div>
                    <div className="ptxt">1對1 張文豪</div>
                    <div className="info">
                      <span>2019/10/23</span>
                    </div>
                  </div>
                </IonLabel>
              </IonItem>
              <IonItemOptions side="end">
                <IonItemOption color="danger" expandable>
                  <IonIcon color="light" slot="icon-only" src="/assets/icon/trash.svg"></IonIcon>
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          </IonList>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Curriculumlist_value);
