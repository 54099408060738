import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText
} from '@ionic/react';
import './Studentlist.css';
import './Sendjob.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent
}

class Sendjob extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null
    };
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.goBack()} text="返回"/>
            </IonButtons>
            <IonTitle>確認接受派工</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="sendjobpage">
          {/*<IonList lines="full" className="stulist">
            <IonItem>
              <IonLabel>
                <div className="stinfo">
                  <div className="title">姓名部分隱藏</div>
                  <div className="ptxt">基本資料</div>
                  <div className="ptxt">適合時間</div>
                  <div className="info">
                    <span>YYYY/MM/DD</span><span>派工單位</span>
                  </div>
                </div>
              </IonLabel>
              <IonButton fill="outline" slot="end" color="dark">接受</IonButton>
            </IonItem>
            <IonItem>
              <IonLabel>
                <div className="stinfo">
                  <div className="title">李**</div>
                  <div className="ptxt">
                    <span>女性</span>
                    <span>30~40歲</span>
                    <span>基督徒不穩定聚會</span>
                  </div>
                  <div className="ptxt">周間晚上</div>
                  <div className="info">
                    <span>2019/11/31</span><span>懷相</span>
                  </div>
                </div>
              </IonLabel>
              <IonButton fill="outline" slot="end" color="dark">接受</IonButton>
            </IonItem>
          </IonList>*/}
          <div className="nextsoon">對課完成2人次以上，此功能才會開啟</div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Sendjob);
