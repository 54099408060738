import React from 'react';

declare var $: any;
declare var window: any;

export class BaseComponent<P, S> extends React.Component<P, S> {
  public subscribed = [];
  public loadPageData_OnlyOnceGuard = false;

  public _doLoadingPageDataInDidMount = true;
  public _doInitInDidMount = true;

  constructor(props?: any) {
    super(props);
  }


  async componentDidMount() {
  }

  async componentWillUnmount() {
  }
}
