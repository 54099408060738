import {BehaviorSubject, Observable, Subject} from "rxjs";


export class StaticEvent {

  public static F1_Online_TabSelectEvent: Subject<any> = new Subject();
  public static F1_Online_TabSelectEvent$: Observable<any> = StaticEvent.F1_Online_TabSelectEvent.asObservable();
  public static F1_Online_ContactList_SelectEvent: Subject<any> = new Subject();
  public static F1_Online_ContactList_SelectEvent$: Observable<any> = StaticEvent.F1_Online_ContactList_SelectEvent.asObservable();

  public static F1_UserInfo_TabSelectEvent: Subject<any> = new Subject();
  public static F1_UserInfo_TabSelectEvent$: Observable<any> = StaticEvent.F1_UserInfo_TabSelectEvent.asObservable();
  public static F1_UserInfo_History_SelectEvent: Subject<any> = new Subject();
  public static F1_UserInfo_History_SelectEvent$: Observable<any> = StaticEvent.F1_UserInfo_History_SelectEvent.asObservable();

  public static F1_ChatInfo_TabSelectEvent: Subject<any> = new Subject();
  public static F1_ChatInfo_TabSelectEvent$: Observable<any> = StaticEvent.F1_ChatInfo_TabSelectEvent.asObservable();

  public static SocketConnectEvent: Subject<any> = new BehaviorSubject<boolean>(false);
  public static SocketConnectEvent$: Observable<any> = StaticEvent.SocketConnectEvent.asObservable();

  public static LoadingEvent: Subject<any> = new Subject();
  public static LoadingEvent$: Observable<any> = StaticEvent.LoadingEvent.asObservable();
}
