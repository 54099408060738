import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonCheckbox
} from '@ionic/react';
import './Login.css';
import {RouteComponentProps} from "react-router";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>
type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,
}

class Emailvertify extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // @ts-ignore
    // @ts-ignore
    // this.state = {
    //   username: null
    // }
  }

  updateUserName(e: CustomEvent) {
  }

  logInUser() {
    this.props.history.push('/');
  }

  signUpUser() {
  }

  render() {
    return (
      <>
        <div className="logo">
          <div className="plustitle">帳號驗證成功</div>
          <div className="count">ruby02181208@gamil.com</div>
          <div className="main_logo">
            <div className="bx1"></div>
            <div className="bx2"></div>
            <div className="bx3"></div>
            <div className="bx4"></div>
            <div className="bx5"></div>
            <div className="bx6"></div>
            <div className="bx7"></div>
            <div className="bx8"></div>
            <div className="bx9"></div>
            <div className="bx10"></div>
            <div className="bx11"></div>
            <div className="bx12"></div>
            <div className="bx13"></div>
            <div className="bx14"></div>
            <div className="bx15"></div>
            <div className="bx16"></div>
            <div className="bx17"></div>
            <div className="bx18"></div>
            <div className="bx19"></div>
            <div className="bx20"></div>
            <div className="bx21"></div>
            <div className="bx22"></div>
            <div className="bx23"></div>
            <div className="bx24"></div>
            <div className="bx25"></div>
            <div className="bx26"></div>
            <div className="bx27"></div>
            <div className="bx28"></div>
            <div className="bx29"></div>
            <div className="bx30"></div>
            <div className="bx31"></div>
            <div className="bx32"></div>
            <div className="bx33"></div>
            <div className="bx34"></div>
            <div className="bx35"></div>
            <div className="bx36"></div>
            <div className="bx37"></div>
            <div className="bx38"></div>
            <div className="bx39"></div>
            <div className="bx40"></div>
            <div className="bx41"></div>
            <div className="bx42"></div>
            <div className="bx43"></div>
            <div className="bx44"></div>
            <div className="bx45"></div>
            <div className="bx46"></div>
            <div className="bx47"></div>
            <div className="bx48"></div>
            <div className="bx49"></div>
            <div className="bx50"></div>
            <div className="bx51"></div>
            <div className="bx52"></div>
            <div className="bx53"></div>
            <div className="bx54"></div>
            <div className="bx55"></div>
            <div className="bx56"></div>
            <div className="bx57"></div>
            <div className="bx58"></div>
            <div className="bx59"></div>
            <div className="bx60"></div>
            <div className="bx61"></div>
            <div className="bx62"></div>
            <div className="bx63"></div>
            <div className="bx64"></div>
            <div className="bx65"></div>
            <div className="bx66"></div>
            <div className="bx67"></div>
            <div className="bx68"></div>
            <div className="bx69"></div>
            <div className="bx70"></div>
            <div className="bx71"></div>
            <div className="bx72"></div>
            <div className="bx73"></div>
            <div className="bx74"></div>
            <div className="bx75"></div>
            <div className="bx76"></div>
            <div className="bx77"></div>
            <div className="bx78"></div>
            <div className="bx79"></div>
            <div className="bx80"></div>
            <div className="bx81"></div>
            <div className="bx82"></div>
            <div className="bx83"></div>
            <div className="bx84"></div>
            <div className="bx85"></div>
            <div className="bx86"></div>
            <div className="bx87"></div>
            <div className="bx88"></div>
            <div className="bx89"></div>
            <div className="bx90"></div>
            <div className="bx91"></div>
            <div className="bx92"></div>
            <div className="bx93"></div>
            <div className="bx94"></div>
            <div className="bx95"></div>
            <div className="bx96"></div>
            <div className="bx97"></div>
            <div className="bx98"></div>
            <div className="bx99"></div>
            <div className="bx100"></div>
          </div>
        </div>
        <form className="loginform">
          <IonList no-lines>
            <IonItem>
              <IonLabel position="floating">設定密碼</IonLabel>
              <IonInput
                name="password"
                type="password"
              >
              </IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">確認密碼</IonLabel>
              <IonInput name="checkpassword" type="password"></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonCheckbox color="primary" slot="start"/>
              <IonLabel className="ptxt">同意，並詳閱服務條款及隱私權政策</IonLabel>
            </IonItem>
          </IonList>

          <IonRow responsive-sm>
            <IonCol>
              <IonButton expand="block" color="primary" type="submit" onClick={this.logInUser}>
                送出
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Emailvertify);
