import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonAlert,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonNote
} from '@ionic/react';
import './Studentlist.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, personAdd, bulb} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import Moment from "react-moment";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  id:any,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}

class Studentlist_st_tutorial extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    // @ts-ignore
    const id = this.props.match.params.id;

    this.state = {
      showPopover: true,
      showPopoverEvent: null,

      id:id,

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/studentlistst');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
        StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
        StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  async handleDeleteSubmit(e: any, id:string) {
    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_studentmanagement_delete');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            await this._loadPageData();

          } else {
            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    let Items: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    let ith: any;

    if (pageData != null) {

      let its = pageData.List_對象;


      if (its.length > 0) {
        Items = <>
          <IonContent className="slideroption">
          {/* <div className='instructions'>
            <ol>
              <li>請點擊下方“對課記錄”進入填寫，並預備下載「逐字講稿」</li>
              <li>該資料夾若還有其他成員，請繼續右上角 <IonIcon icon={personAdd} slot="icon-only"></IonIcon>「新增對象基本資料」，填妥後將回到此頁，此時再點擊下方“對課記錄”，預備下載「逐字講稿」</li>
            </ol>
          <p><IonIcon icon={bulb}></IonIcon>小技巧：按住對象名稱往左拖拉，就會出現“刪除對象資料“功能</p> 
          </div>*/}

          <IonList lines="full" className="stulist">
            {its.map(it => (
              <IonItemSliding key={it.對象Id}>
                <IonItem button
                         href={`/studentmanagement/${it.對象Id}`} onClick={
                  (e) => {
                    e.preventDefault();
                    this.props.history.push(`/studentmanagement/${it.對象Id}`);
                  }}>
                  <IonLabel>
                    <div className="stinfo">
                      <div className="title">{it.姓名}</div>
                      <div className="info">
                        <span>{it.性別}</span>
                        <span>{it.年齡區間}</span>
                        {/* <span>{it.課前信仰背景}</span> */}
                      </div>
                    </div>
                    {it.課程中止時間 != null &&
                    <div className="stlrsit">
                        <div className="curs">中止</div>
                        <div className="time"><Moment format="YYYY/MM/DD">{it.課程中止時間}</Moment></div>
                    </div>
                    }
                  </IonLabel>
                </IonItem>
                <IonItemOptions side="end">
                  <IonItemOption color="danger" expandable>
                    <IonIcon color="light" slot="icon-only" src="/assets/icon/trash.svg" onClick={(e)=>this.handleDeleteSubmit(e, it.對象Id)}></IonIcon>
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>))}

          </IonList>

                  <div className="cus_link">
                  <IonButton color="primary" onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push(`/curriculum_tutorial/${this.state.id}`);
                }}>前往下載「逐字講稿」</IonButton>
                  <IonNote>進入後選擇將要對「第幾課」以便下載逐字講稿</IonNote>

                  </div>


          </IonContent>
        </>;
      } else {
        Items = <>
          <IonContent className="nocontent">
            <div className="norole">
              <div className="txtcontent">點擊右上角<IonIcon icon={personAdd} slot="icon-only"></IonIcon> 填寫對象資料
              <div className="sub_th">若對象(夫婦)超過一人，則在填寫第一人個資後，繼續點擊右上角<IonIcon icon={personAdd} slot="icon-only"></IonIcon>，可填寫第二人</div>

              </div>
            </div>

          {/* <div className="norole notify" onClick={
            (e) => {
              e.preventDefault();
              this.props.history.push(`/studentdatainputstep1/${this.state.id}`);
            }}>

          </div> */}

          {/* <div className="noteintro">
            <h4>注意事項</h4>
            <ol>
              <li>【一對一】解答個人信仰疑問，效果最好</li>
              <li>若需“一對二”以上，建議帶領者已很熟悉課程內容，且對象僅用於<span>一家人</span>、<span>夫婦</span>、<span>男女朋友</span>或<span>可以知道彼此穩私的好友</span>，新增第2個或更多對象，請再次點擊右上角 <IonIcon icon={personAdd}></IonIcon></li>
              {/*<li>若同一梯次課程有多人一起上課，請填寫完對象資料後，再次新增欲加入的對象，可重複此行為，人數最高上限為10人</li>
            </ol>
          </div> */}
          </IonContent>
        </>;
      }
    } else {

    }

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>

            {this.state.pageData &&
            <IonTitle>對象</IonTitle>
            }
            <IonButtons slot="end">
              <IonButton onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push(`/studentdatainputstep1_tutorial/${this.state.id}`);
                }}>
                <IonIcon icon={personAdd} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>



          {/*<IonAlert*/}
          {/*  isOpen={this.state.showPopover}*/}
          {/*  onDidDismiss={() => this.setState(*/}
          {/*    () => ({showPopover: false})*/}
          {/*  )}*/}
          {/*  header={'您要直接下載逐字稿嗎?'}*/}
          {/*  //subHeader={'Subtitle'}*/}
          {/*  message={'下載逐字稿前，請先安排上課日期'}*/}
          {/*  buttons={[*/}
          {/*    {*/}
          {/*      text: '不需要',*/}
          {/*      role: 'cancel',*/}
          {/*      cssClass: 'tertiary',*/}
          {/*      handler: blah => {*/}
          {/*        console.log('Confirm Cancel: blah');*/}
          {/*      }*/}
          {/*    },*/}
          {/*    {*/}
          {/*      text: '好的',*/}
          {/*      handler: () => {*/}
          {/*        console.log('Confirm Okay');*/}
          {/*      }*/}
          {/*    }*/}
          {/*  ]}*/}
          {/*>*/}
          {/*</IonAlert>*/}


          {Items}


      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Studentlist_st_tutorial);
