import React, {useState, Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonModal,
  IonToast,
  IonSegmentButton,
  IonAlert, IonItemSliding, IonItemOptions, IonItemOption
} from '@ionic/react';
import './Curriculumlist.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, addCircleOutline,folder,bulb,resize} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import Moment from 'react-moment';
import {StaticEvent} from "../_/StaticEvent";
import ReactHtmlParser from 'react-html-parser';


type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  tabName: string,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,


  showAlert_4: boolean,
  showAlertData_4: any,

  showAlert_5: boolean,
  showAlertData_5: any,
  showToast: boolean,
}


function styleNameList(nameList:string, className: string = null) {
  if (nameList == null || nameList == '')
    return;

  var output = (nameList).split(',');
  var output_with_qoutes = null;

  if (className === null) {
    output_with_qoutes = output.map((r) => {
      return `<span>${r}</span>`
    });
  } else {
    output_with_qoutes = output.map((r) => {
      return `<span class="${className}">${r}</span>`
    });
  }
  return ReactHtmlParser(output_with_qoutes.join(''));
}

export const ModalAX: React.FC = () => {
  const [showModal, setShowModal] = useState(true);

  return (
      <IonModal isOpen={showModal}>
         <div className="modal_page">
         <div className="choice_item">
            <IonButton size="large" expand="block" onClick={() => setShowModal(false)}>非初次對課</IonButton>
          {/* <IonButton size="large" expand="block" href="/root">非初次對課</IonButton> */}
           <div className="item_th">新增對象點選並繼續</div>
          
          </div>
          <div className="choice_item xs">
           <div className="item_th ">若為<span className="bold">"初次對課"</span>請返回首頁「下載逐字講稿」專區</div>
           <IonButton className="narrow" color="tertiary" size="small"  fill="clear" href="/root"><IonIcon src="/assets/icon/chevron-back-outline.svg" slot="icon-only"></IonIcon> 返回首頁</IonButton>
           
          </div>
        </div>
        
        {/* <ionHint></ionHint> */}
        {/* <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton> */}
      </IonModal>
  );
};

class Curriculumlist extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      showAlert_4: false,
      showAlertData_4: {},

      showAlert_5: false,
      showAlertData_5: {},
      showToast: false,

      tabName: 'a',

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };

    

  }


  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // // @ts-ignore
    // const {username, password} = this.state;
    // if (!username || !password) {
    //   return; //ERROR INPUTS
    // }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/curriculumlist');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  async handleRenameShow(e: any, p1: any) {
    await this.setState(() => ({
      showAlert_5: true,
      showAlertData_5: {p1},
    }));
  }

  async _handleRenameSubmit(e: any) {

    let {p1, p2} = this.state.showAlertData_5;

    if (!p1 || !p2) {
      return;
    }


    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: p1,
      p2: p2,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_curriculummanagement_rename');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);


            //refresh pageData
            await this._loadPageData();

          } else {
            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  async handleAddShow(e: any) {
    await this.setState(() => ({
      showAlert_4: true,
      showAlertData_4: {},
    }));
  }

  async _handleAddSubmit(e: any) {
    if (e) {
      e.preventDefault();
    }

    let {p1} = this.state.showAlertData_4;

    if (!p1) {
      return;
    }

    // let p1 = prompt('請輸入對象名稱');
    // if (p1 === null) {
    //   return; //exit if prompt not entered
    // }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: p1,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_curriculumlist_add');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            let {Value_對課Id} = payload;
            // this.props.history.push(`/curriculummanagement/${Value_對課Id}`);

            //refresh pageData
            await this._loadPageData();

          } else {
            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  async handleDeleteSubmit(e: any, id: string) {
    if (!confirm('確定要刪除嗎?')) {
      return;
    }


    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_curriculummanagement_delete');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            await this._loadPageData();

          } else {
            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  handleAnimationEnd = () => {
    // 当动画结束时，将元素移除
    this.setState({ showToast: false });
  };


  render() {
    let Items: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');
    const { showToast } = this.state;

    if (pageData != null) {
      let List_對課 = [];
      if (this.state.tabName === 'a') {
        List_對課 = pageData.List_對課.filter(v => (v._完結 === false));
      } else if (this.state.tabName === 'b') {
        List_對課 = pageData.List_對課.filter(v => (v._完結 === true));
      }

      if (List_對課.length > 0) {
        //對象姓名、<span className="stop">已暫停對象姓名</span>
        Items = <>
          <IonList lines="full" >
            {List_對課.map(it => (
              <IonItemSliding key={it.對課Id} className="listpanel">
                <IonItem button
                         href="/curriculummanagement" onClick={
                  (e) => {
                    e.preventDefault();
                    this.props.history.push(`/studentlistst/${it.對課Id}`)
                    // if(it._對象人數 <= 0){
                    //   this.props.history.push(`/studentlistst/${it.對課Id}`)

                    // }else if(it._對象人數 >= 1){
                    //   this.props.history.push(`/curriculummanagement/${it.對課Id}`);
                    // }
                  }} className="listcube">
                    <IonIcon icon={folder} color="warning"  slot="start" ></IonIcon>
                  <IonLabel>

                      <div className="title">{it.案件名稱}</div>

                      <div
                        className="ptxt">{it._對課類型} {styleNameList(it._對象姓名_完結, 'started')}{styleNameList(it._對象姓名_未完結, 'halt')}
                        {it._最後第幾課 !== null &&
                      <>
                        <div className="ptxt">對課進度:L{it._最後第幾課} <Moment format="YYYY/MM/DD" date={it._最後上課時間}/></div>
                      </>
                      }

                      {/* <div className="ptxt">逐字稿最新下載至:L{it._最後第幾課}</div> 202208新增規格 -- 增加逐字稿下載紀錄*/}

                      {/* <div className="info">
                        <span><Moment format="YYYY/MM/DD hh:mm" date={it.CreatedAt}/></span>
                      </div>  */}
                    </div>

                  </IonLabel>

                </IonItem>
                <IonItemOptions side="end">
                  <IonItemOption color="twitter" expandable onClick={(e) => this.handleRenameShow(e, it.對課Id)}>
                    <IonIcon color="light" slot="icon-only" src="/assets/icon/writing.svg"></IonIcon>
                  </IonItemOption>
                  <IonItemOption color="danger" expandable onClick={(e) => this.handleDeleteSubmit(e, it.對課Id)}>
                    <IonIcon color="light" slot="icon-only" src="/assets/icon/trash.svg"></IonIcon>
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>

            ))}

          </IonList>
        </>;
      } else {
        if (this.state.tabName === 'a') {
          Items = <>
            <div className="norole curr" onClick={(e) => {
              e.preventDefault();
              this.handleAddShow(e);
            }}>
              <div className="icon"></div>
              <div className="txtcontent">先按右上角 <IonIcon icon={addCircleOutline} slot="icon-only"></IonIcon> <br/>建立「對課對象資料夾」</div>
            </div>
          </>;
        }
      }
    }

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.replace(`/root`);
              }} text="返回"/>
            </IonButtons>
            <IonTitle>對課自助管理</IonTitle>
            <IonButtons slot="end">
              <IonButton icon-only href="/" onClick={(e) => {
                e.preventDefault();
                this.handleAddShow(e);
              }}>
                <IonIcon icon={addCircleOutline} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>
        <IonAlert
          isOpen={this.state.showAlert_4}
          onDidDismiss={() => this.setState(() => ({showAlert_4: false}))}
          header={'📁 請為「資料夾」命名 '}
          inputs={[
            {
              name: 'p1',
              type: 'text',
              placeholder: '對象名稱，例如：王小明或林夫婦」',

            },
          ]}
          // message='請自行決定該「資料夾名稱」'
          buttons={[
            {
              text: '取消',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            },
            {
              text: '建立',
              handler: async (v) => {

                await this.setState({
                  showAlert_4: false,
                  showAlertData_4: {
                    ...this.state.showAlertData_4,
                    ...v,
                  },
                  showToast: true
                });

                await this._handleAddSubmit(null);
              }
            }
          ]}
        />

        <IonAlert
          isOpen={this.state.showAlert_5}
          onDidDismiss={() => this.setState(() => ({showAlert_5: false}))}
          header={'修改資料夾名稱'}
          inputs={[
            {
              name: 'p2',
              type: 'text',
              placeholder: '請輸入想要修改的名稱',
            },
          ]}
          buttons={[
            {
              text: '取消',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            },
            {
              text: '儲存',
              handler: async (v) => {

                await this.setState({
                  showAlert_5: false,
                  showAlertData_5: {
                    ...this.state.showAlertData_5,
                    ...v,
                  },
                });

                await this._handleRenameSubmit(null);
              }
            }
          ]}
        />


        <IonContent className="nocontent slideroption">
          <IonSegment onIonChange={e => {
            console.log('Segment selected', e.detail.value);
            this.setState({tabName: e.detail.value});
          }} mode="md">
            <IonSegmentButton value="a" {...{'checked': this.state.tabName === 'a'}} >
              <IonLabel>對課中</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="b" {...{'checked': this.state.tabName === 'b'}}>
              <IonLabel>完課對象資料夾</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {/* <div className='instructions'>
            <ul>
              <li>新增對象請按右上角 <IonIcon icon={addCircleOutline} slot="icon-only"></IonIcon></li>
            </ul>
          </div> */}

          {Items}
          <ModalAX/>

          <>{
            this.state.showToast ? (
              <div className={`toast-wrapper ${showToast ? 'active' : ''}`} onAnimationEnd={this.handleAnimationEnd}>
          <div className="toast-box">
                  <div className="toast-msg">請點擊該資料夾，填寫對象資料</div>
                </div>
      </div>
          ):null
          }</>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Curriculumlist);
