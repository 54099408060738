import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonTextarea
} from '@ionic/react';
import './Emailverify.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import Studentmanagement from "./Studentmanagement";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,
  Item_對象: any,
}

const customActionSheetOptions1 = {
  header: '請選擇對象信仰背景',
};
const customActionSheetOptions2 = {
  header: '請選擇對象信仰狀況',
};
const customActionSheetOptions3 = {
  header: '請選擇對象聚會狀況',
};

class Studentdataedit extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,
      Item_對象: {},
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }



  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/studentdataedit');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            await this.setState({
              pageData: payload,
            });
            await this.setState({
              Item_對象: this.state.pageData.Item_對象
            });


          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  handleInputChange(e) {
    let newItem = Object.assign({}, this.state.Item_對象, {
      [e.target.name]: e.target.value,
    });

    this.setState({
      Item_對象: newItem,
    });
  }

  async handleSaveSubmit(e: any) {
    e.preventDefault();

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: this.state.Item_對象,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_studentdataedit');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            this.props.history.goBack();
            //await this._loadPageData();

          } else {
            debugger;

            alert(error.message);
            // const controller: any = document.querySelector('ion-toast-controller');
            // let msg = await controller.create({
            //   message: error.message,
            //   duration: 2000,
            //   position: 'top'
            // });
            // await msg.present();
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  render() {

    console.dir(this.state.pageData);


    // {e => {
    //
    //   e.stopPropagation();
    //   // @ts-ignore
    //   this.refs.Form.getDOMNode().dispatchEvent(new Event("submit"));
    // }}

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            <IonTitle>對象資料編輯</IonTitle>
            {this.state.pageData &&
            <IonButtons slot="end">
              <IonButton onClick={e => this.handleSaveSubmit(e)}>
                儲存
              </IonButton>
            </IonButtons>
            }
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="inputform">
          {this.state.pageData &&
          <>
            <div className="titlebar">
              <h3>基本資料</h3>
              <div className="note">
                <span>*</span>為必填
              </div>
            </div>
            <form ref="Form" noValidate>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">姓名 <span>*</span></IonLabel>
                  <IonInput name="姓名"
                            value={this.state.Item_對象.姓名}
                            onIonChange={(e: any) => this.handleInputChange(e)} type="text" required
                            placeholder="請輸入姓名"></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">性別 <span>*</span></IonLabel>
                  <IonSelect name="性別"
                             value={this.state.Item_對象.性別}
                             onIonChange={(e: any) => this.handleInputChange(e)} interface="action-sheet"
                             placeholder="請選擇"
                             okText="確認" cancelText="取消">
                    <IonSelectOption value="女性">女性</IonSelectOption>
                    <IonSelectOption value="男性">男性</IonSelectOption>
                  </IonSelect>
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="stacked">想用的語言</IonLabel>
                  <IonInput name="語言"
                            value={this.state.Item_對象.語言}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="text"
                            placeholder="請輸入語言"></IonInput>
                </IonItem> */}
                <IonItem>
                  <IonLabel position="stacked">年齡區間 <span>*</span></IonLabel>
                  <IonSelect name="年齡區間"
                             value={this.state.Item_對象.年齡區間}
                             onIonChange={(e: any) => this.handleInputChange(e)} interface="action-sheet"
                             placeholder="請選擇"
                             okText="確認" cancelText="取消">
                    <IonSelectOption value="20歲以下">20歲以下</IonSelectOption>
                    <IonSelectOption value="20~30歲">20~30歲</IonSelectOption>
                    <IonSelectOption value="30~40歲">30~40歲</IonSelectOption>
                    <IonSelectOption value="40~50歲">40~50歲</IonSelectOption>
                    <IonSelectOption value="50~60歲">50~60歲</IonSelectOption>
                    <IonSelectOption value="60歲以上">60歲以上</IonSelectOption>
                  </IonSelect>
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="stacked">所在地區</IonLabel>
                  <IonSelect name="所在地區"
                             value={this.state.Item_對象.所在地區}
                             onIonChange={(e: any) => this.handleInputChange(e)} interface="action-sheet"
                             placeholder="請選擇"
                             okText="確認" cancelText="取消">
                    <IonSelectOption value="台灣">台灣</IonSelectOption>
                    <IonSelectOption value="國外">國外</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">所在地點</IonLabel>
                  {this.state.Item_對象.所在地區 === "台灣" &&
                  <IonInput name="所在地點"
                            value={this.state.Item_對象.所在地點}
                            onIonChange={(e: any) => this.handleInputChange(e)} type="text"
                            placeholder="請輸入縣市及行政區"></IonInput>
                  }
                  {this.state.Item_對象.所在地區 === "國外" &&
                  <IonInput name="所在地點"
                            value={this.state.Item_對象.所在地點}
                            onIonChange={(e: any) => this.handleInputChange(e)} type="text"
                            placeholder="請輸入國家及城市"></IonInput>
                  }
                </IonItem> */}
                {/* <IonItem>
                  <IonLabel position="stacked">職業</IonLabel>
                  <IonSelect name="職業"
                             value={this.state.Item_對象.職業}
                             onIonChange={(e: any) => this.handleInputChange(e)} interface="action-sheet"
                             placeholder="請選擇"
                             okText="確認" cancelText="取消">
                    <IonSelectOption value="企業負責人">企業負責人</IonSelectOption>
                    <IonSelectOption value="公司主管">公司主管</IonSelectOption>
                    <IonSelectOption value="上班族">上班族</IonSelectOption>
                    <IonSelectOption value="服務業">服務業</IonSelectOption>
                    <IonSelectOption value="學生">學生</IonSelectOption>
                    <IonSelectOption value="家管">家管</IonSelectOption>
                    <IonSelectOption value="退休人員">退休人員</IonSelectOption>
                    <IonSelectOption value="其他">其他</IonSelectOption>
                  </IonSelect>

                </IonItem>
                {this.state.Item_對象.職業 === "其他" &&
                  <IonItem>
                  <IonInput name="職業_其他"
                            value={this.state.Item_對象.職業_其他}
                            onIonChange={(e: any) => this.handleInputChange(e)} type="text"
                            placeholder="請輸入職業"></IonInput>
                  </IonItem>
                  } */}
                {/* <IonItem>
                  <IonLabel position="stacked">婚姻狀況</IonLabel>
                  <IonSelect name="婚姻狀況"
                             value={this.state.Item_對象.婚姻狀況}
                             onIonChange={(e: any) => this.handleInputChange(e)} interface="action-sheet"
                             placeholder="請選擇"
                             okText="確認" cancelText="取消">
                    <IonSelectOption value="已婚">已婚</IonSelectOption>
                    <IonSelectOption value="未婚">未婚</IonSelectOption>
                    <IonSelectOption value="離異">離異</IonSelectOption>
                    <IonSelectOption value="喪偶">喪偶</IonSelectOption>
                  </IonSelect>
                </IonItem> */}
              </IonList>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">對象來源 <span>*</span></IonLabel>
                  <IonSelect name="對象來源"
                             value={this.state.Item_對象.對象來源}
                             onIonChange={(e: any) => this.handleInputChange(e)} interface="action-sheet"
                             placeholder="請選擇"
                             okText="確認" cancelText="取消">
                    <IonSelectOption value="教會派工">教會派工</IonSelectOption>
                    <IonSelectOption value="別人介紹">別人介紹</IonSelectOption>
                    <IonSelectOption value="自邀對象">自邀對象</IonSelectOption>
                    <IonSelectOption value="懷相派工">懷相派工</IonSelectOption>
                  </IonSelect>
                  {this.state.Item_對象.對象來源 === "自邀對象" &&
                  <IonInput name="對象來源_與工人關係"
                            value={this.state.Item_對象.對象來源_與工人關係}
                            onIonChange={(e: any) => this.handleInputChange(e)} type="text"
                            placeholder="請輸入對象與工人關係"></IonInput>
                  }
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="stacked">其他說明</IonLabel>
                  <IonTextarea name="其他說明"
                               value={this.state.Item_對象.其他說明}
                               rows={6} onInput={(e: any) => this.handleInputChange(e)}
                               placeholder="請輸入說明"></IonTextarea>
                </IonItem> */}
              </IonList>

              <div className="titlebar">
                <h3>信仰狀態</h3>
              </div>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">課前信仰背景 <span>*</span></IonLabel>
                  <IonSelect name="課前信仰背景"
                             value={this.state.Item_對象.課前信仰背景}
                             onIonChange={(e: any) => this.handleInputChange(e)}
                             interfaceOptions={customActionSheetOptions1}
                             interface="action-sheet" placeholder="請選擇" okText="確認" cancelText="取消">
                    <IonSelectOption value="基督徒">基督徒</IonSelectOption>
                    <IonSelectOption value="慕道友">慕道友</IonSelectOption>
                    <IonSelectOption value="無任何信仰">無任何信仰</IonSelectOption>
                    <IonSelectOption value="其他宗教信仰">其他宗教信仰</IonSelectOption>
                  </IonSelect>
                </IonItem>
                  {this.state.Item_對象.課前信仰背景 === "慕道友" &&
                  <IonItem>
                  <IonInput name="課前信仰背景_慕道"
                            value={this.state.Item_對象.課前信仰背景_慕道}
                            onIonChange={(e: any) => this.handleInputChange(e)} type="text"
                            placeholder="請輸入慕道多少年"></IonInput>
                  </IonItem>
                  }
                  {this.state.Item_對象.課前信仰背景 === "其他宗教信仰" &&
                  <IonItem>
                    <IonInput name="課前信仰背景_其他"
                              value={this.state.Item_對象.課前信仰背景_其他}
                              onIonChange={(e: any) => this.handleInputChange(e)} type="text"
                              placeholder="請輸入其他宗教名稱"></IonInput>
                  </IonItem>
                  }


                {this.state.Item_對象.課前信仰背景 === "基督徒" &&
                <IonItem>
                  <IonLabel position="stacked">課前信仰狀況 <span>*</span></IonLabel>
                  <IonSelect name="課前信仰狀況_信"
                             value={this.state.Item_對象.課前信仰狀況_信}
                             onIonChange={(e: any) => this.handleInputChange(e)}
                             interfaceOptions={customActionSheetOptions2}
                             interface="action-sheet"
                             placeholder="請選擇信仰狀況" okText="確認" cancelText="取消">
                    <IonSelectOption value="已決志">已決志</IonSelectOption>
                    <IonSelectOption value="已受洗">已受洗</IonSelectOption>
                  </IonSelect>

                </IonItem>
                }
                {(this.state.Item_對象.課前信仰背景 === "基督徒") && (this.state.Item_對象.課前信仰狀況_信 === "已決志" || this.state.Item_對象.課前信仰狀況_信 === "已受洗") &&
                <IonItem>
                  <IonLabel position="stacked">課前聚會狀況 <span>*</span></IonLabel>
                  <IonSelect name="課前信仰狀況_聚會"
                             value={this.state.Item_對象.課前信仰狀況_聚會}
                             onIonChange={(e: any) => this.handleInputChange(e)}
                             interfaceOptions={customActionSheetOptions3}
                             interface="action-sheet"
                             placeholder="請選擇聚會狀況" okText="確認" cancelText="取消">
                    <IonSelectOption value="穩定聚會">穩定聚會</IonSelectOption>
                    <IonSelectOption value="不穩定聚會">不穩定聚會</IonSelectOption>
                    <IonSelectOption value="遠離教會">遠離教會</IonSelectOption>
                  </IonSelect>
                </IonItem>
                }
              </IonList>


            </form>
          </>}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
});

export default connect(
  mapStateToProps
)(Studentdataedit);
