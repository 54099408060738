import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonGrid
} from '@ionic/react';
import './About.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";

const slideOpts = {
  initialSlide: 1,
  speed: 400
};

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  segment: string;
  isRefreshing: boolean;
  showLoading: boolean;
  showFilterModal: boolean;
  loadingMessage: string;
};

class About extends Component<Props, State> {
  ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;
  ionFabRef: React.RefObject<HTMLIonFabElement>;
  state = {
    segment: 'all',
    isRefreshing: false,
    showLoading: false,
    showFilterModal: false,
    loadingMessage: ''
  };

  constructor(props: Props) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.ionFabRef = React.createRef<HTMLIonFabElement>();
  }

  presentFilter = () => {
    this.setState(() => ({
      showFilterModal: true
    }));
  };


  openSocial = (network: string) => {
    this.setState(() => ({
      loadingMessage: `Posting to ${network}`,
      showLoading: true
    }));

    setTimeout(() => {
      this.setState(() => ({showLoading: false}));
    }, Math.random() * 1000 + 500);

    if (this.ionFabRef.current) {
      this.ionFabRef.current.close();
    }
  };

  updateSegment = (e: CustomEvent) => {
    this.setState(prevState => ({
      ...prevState,
      segment: e.detail.value
    }));
  };

  doRefresh = () => {
    setTimeout(() => {
      this.setState(() => ({isRefreshing: true}));
      if (this.ionRefresherRef.current) {
        this.ionRefresherRef.current.complete();
      }
    }, 500);
  };

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.goBack()} text="返回"/>
            </IonButtons>
            <IonTitle>關於懷相工人平台</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
            <IonGrid className="termspage">
              <IonRow>
              <IonCol>
              <div className="termsinfo">
          <h2>沒有屬靈的話語，用理性、科學、歷史角度來看聖經</h2>
          <h3>『從懷疑到相信』課程源起</h3>
          <p>『從懷疑到相信』課程是由姜文琪姊妹所編製，雖然沒有神學背景，但在她信主第三年起(西元2002年)，蒙神帶領及開啟，透過一對一傳福音的機會，揣摩新朋友可能對信仰產生的疑問，在讀經禱告、聖靈啟發，加上蒐集傳道人分享資料後，三年內分析彙整成「從懷疑到相信」傳福音工具，同時8年一對一領百人信主。蒙神帶領她除了於2006年起在台灣各地開班，同時2012年起將課程帶到世界各地，不但幫助未信者(新朋友)能了解簡單易懂的真理而信主進教會，同時也教導提供許多基督徒一個完整工具，能向周圍親友傳講基督信仰。</p>
          </div>
          </IonCol>
      </IonRow>
    </IonGrid>
        </IonContent>

      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(About);
