import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonAlert
} from '@ionic/react';
import './Studentlist.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, personAdd} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}

class Studentlist extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/studentlistst');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
        StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
        StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    return (
      <>

        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            <IonTitle>對象</IonTitle>
            <IonButtons slot="end">
              <IonButton href="/studentdatainputstep1" onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push('/studentdatainputstep1');
                }}>
                <IonIcon icon={personAdd} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="nocontent">


          <div className="norole notify" onClick={
            (e) => {
              e.preventDefault();
              this.props.history.push('/studentdatainputstep1');
            }}>
            <div className="icon"></div>
            <div className="txtcontent">填入對象詳細資料</div>
          </div>
          <div className="noteintro">
            <h4>注意事項</h4>
            <ol>
              <li>【一對一】解答難題及信仰疑問效果最好</li>
              <li>若需“一對二”以上，建議帶領者已很熟悉課程內容，且對象僅用於一家人、夫婦、男女朋友或對象是可以知道彼此穩私的好友。</li>
              <li>此時請透過右上角的<IonIcon icon={personAdd} slot="icon-only"></IonIcon> 新增對象功能，每一位「對象名稱」都需要做一次 <IonIcon icon={personAdd} slot="icon-only"></IonIcon>新增動作</li>
            </ol>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Studentlist);
