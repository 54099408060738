import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonGrid
} from '@ionic/react';
import './Studentlist.css';
import './Termsprivacy.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";

const slideOpts = {
  initialSlide: 1,
  speed: 400
};

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  segment: string;
  isRefreshing: boolean;
  showLoading: boolean;
  showFilterModal: boolean;
  loadingMessage: string;
};

class Termsprivacy extends Component<Props, State> {
  ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;
  ionFabRef: React.RefObject<HTMLIonFabElement>;
  state = {
    segment: 'all',
    isRefreshing: false,
    showLoading: false,
    showFilterModal: false,
    loadingMessage: ''
  };

  constructor(props: Props) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.ionFabRef = React.createRef<HTMLIonFabElement>();
  }

  presentFilter = () => {
    this.setState(() => ({
      showFilterModal: true
    }));
  };


  openSocial = (network: string) => {
    this.setState(() => ({
      loadingMessage: `Posting to ${network}`,
      showLoading: true
    }));

    setTimeout(() => {
      this.setState(() => ({showLoading: false}));
    }, Math.random() * 1000 + 500);

    if (this.ionFabRef.current) {
      this.ionFabRef.current.close();
    }
  };

  updateSegment = (e: CustomEvent) => {
    this.setState(prevState => ({
      ...prevState,
      segment: e.detail.value
    }));
  };

  doRefresh = () => {
    setTimeout(() => {
      this.setState(() => ({isRefreshing: true}));
      if (this.ionRefresherRef.current) {
        this.ionRefresherRef.current.complete();
      }
    }, 500);
  };

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.goBack()} text="返回"/>
            </IonButtons>
            <IonTitle>隱私權及會員服務條款</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
            <IonGrid className="termspage">
              <IonRow>
              <IonCol>
              <div  className="termsinfo">
          <h2>從懷疑到相信隱私權政策內容</h2>
          <p>我同意「從懷疑到相信」機構可以使用以上個人資料，當您勾選【我同意】本同意書聲明時，表示您已閱讀、瞭解並同意接受所有內容及其後修改變更規定。若您未滿二十歲，應於您的法定代理人閱讀、瞭解並同意及其後修改變更規定後，方得使用本服務，但若您已接受本服務，視為您已取得法定代理人之同意，並遵守相關規範。</p>
          <p>「從懷疑到相信」（以下簡稱:「懷相D2B」）尊重並保護您的隱私權。為了幫助您瞭解「懷相D2B」使用工人平台，統計分析、處理您個人資料，請務必詳細閱讀「懷相D2B」的「隱私權聲明」。</p>
          <h3>壹、適用範圍</h3>
          <ol>
            <li>
              「懷相D2B」「隱私權聲明」適用於您與「懷相D2B」洽辦、參與各項活動（如報名研討會/課程、加入網站會員、訂閱電子報等）或透過電話、傳真或「懷相D2B」網站意見信箱提出詢問或建議時（包括「懷相D2B」官網及「懷相D2B」各相關部門網站），所涉及之個人資料蒐集、處理與利用行為。
            </li>
            <li>
              凡經由「懷相D2B」網站連結至第三方獨立管理、經營之網站，有關個人資料的保護，適用第三方或各該網站的隱私權政策，「懷相D2B」不負任何連帶責任。
            </li>
          </ol>
          <h3>貳、個人資料之蒐集、處理及利用</h3>
          <ol>
            <li>
              當您與「懷相D2B」洽辦或參與「懷相D2B」各項活動，我們將視業務或活動性質請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，「懷相D2B」不會將個人資料用於其他用途。
            </li>
            <li>
              如果您使用電話、傳真或「懷相D2B」網站意見信箱與「懷相D2B」聯繫時，請您提供正確的電話、傳真號碼或電子信箱地址，作為回覆來詢事項之用。
            </li>
            <li>
              您的個人資料在處理過程中，「懷相D2B」將遵守相關之流程及內部作業規範，並依據資訊安全之要求，進行必要之人員控管。
            </li>
            <li>
              單純瀏覽「懷相D2B」網站及下載檔案之行為，「懷相D2B」不會蒐集任何與個人身分有關之資訊。
            </li>
          </ol>
          <h3>參、與第三人共用個人資料</h3>
          <ol>
            <li>
              「懷相D2B」絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
            </li>
            <li>
              前項但書之情形包括不限於
              <ol>
                <li>配合司法單位合法的調查。</li>
                <li>配合主管機關依職權或職務需要之調查或使用（例如審計部或會計師查帳）。</li>
                <li>基於善意相信揭露您的個人資料為法律所必需。</li>
                <li>當您在「懷相D2B」網站的行為，違反「懷相D2B」的服務條款或可能損害或妨礙「懷相D2B」權益或導致任何人遭受損害時，經「懷相D2B」研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
                <li>基於委外契約關係，「懷相D2B」依約履行提供個人資料之義務。</li>
              </ol>
            </li>
            <li>
              「懷相D2B」委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
            </li>
          </ol>
          <h3>肆、cookie之運用</h3>
          <ol>
                <li>基於網站內部管理之需要及提供最佳個人化服務，「懷相D2B」網站將在您的瀏覽器中寫入cookies並讀取記錄瀏覽者的 IP 位址、上網時間，以及在各項資訊查閱之次數，進行網站流量和網路行為調查之總量分析，「懷相D2B」不會利用此伺服器紀錄對「個別」瀏覽者進行分析。</li>
                <li>若您不願接受 cookie 的寫入，您可將使用中之瀏覽器設定為拒絕 cookie 的寫入，但也因此會使網站某些功能無法正常執行。</li>
          </ol>
          <h3>伍、伺服器紀錄</h3>
          <p>當您透過瀏覽器、應用程式或其他用戶端使用「懷相D2B」網站時，我們的伺服器會自動記錄特定的技術性資訊。這些伺服器紀錄可能包含您的網頁要求、網際網路通訊協定位址、瀏覽器類型、瀏覽器語言、送出要求的日期和時間等資訊。此伺服器紀錄僅作為伺服器管理的參考，「懷相D2B」不會利用此伺服器紀錄對「個別」瀏覽者進行分析。</p>
          <h3>陸、隱私權聲明之修改</h3>
          <p>本隱私權聲明將適時依據法律修正、相關技術之發展及內部管理制度之調整而配合修改，以落實保障您隱私權及網路安全之初衷。當「懷相D2B」完成相關條款修改時，會立即將其刊登於「懷相D2B」網站中，並以醒目標示提醒您前往點選閱讀。</p>
          </div> 
          </IonCol>
      </IonRow>
    </IonGrid>
        </IonContent>

      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Termsprivacy);
