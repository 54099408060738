import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonTextarea
} from '@ionic/react';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import SessionList from '../components/SessionList';
import SessionListFilter from '../components/SessionListFilter';
import {actions, RootState, selectors} from '../store';
import './Setting.css';
import {share, logoVimeo, logoGoogleplus, logoTwitter, logoFacebook, options} from 'ionicons/icons';

const slideOpts = {
  initialSlide: 1,
  speed: 400
};

type Props = RouteComponentProps<{}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

type State = {
  segment: string;
  isRefreshing: boolean;
  showLoading: boolean;
  showFilterModal: boolean;
  loadingMessage: string;
};

class Setting extends Component<Props, State> {
  ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;
  ionFabRef: React.RefObject<HTMLIonFabElement>;
  state = {
    segment: 'all',
    isRefreshing: false,
    showLoading: false,
    showFilterModal: false,
    loadingMessage: ''
  };

  constructor(props: Props) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.ionFabRef = React.createRef<HTMLIonFabElement>();
  }

  presentFilter = () => {
    this.setState(() => ({
      showFilterModal: true
    }));
  };

  updateSearchTerm = (e: CustomEvent) => {
    this.props.setSearchText(e.detail.value);
  };

  openSocial = (network: string) => {
    this.setState(() => ({
      loadingMessage: `Posting to ${network}`,
      showLoading: true
    }));

    setTimeout(() => {
      this.setState(() => ({showLoading: false}));
    }, Math.random() * 1000 + 500);

    if (this.ionFabRef.current) {
      this.ionFabRef.current.close();
    }
  };

  updateSegment = (e: CustomEvent) => {
    this.setState(prevState => ({
      ...prevState,
      segment: e.detail.value
    }));
  };

  doRefresh = () => {
    setTimeout(() => {
      this.setState(() => ({isRefreshing: true}));
      if (this.ionRefresherRef.current) {
        this.ionRefresherRef.current.complete();
      }
    }, 500);
  };

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.goBack()} text="返回"/>
            </IonButtons>
            <IonTitle>設定</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="setting-page slideroption" scroll-y="false">
          <IonList>
            <IonItem button
                     href="/personaldata" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/personaldata');
              }}>
              <IonLabel>個人資料</IonLabel>
            </IonItem>
            {/*<IonItem button
                     href="/teachingmaterial" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/teachingmaterial');
              }}>
              <IonLabel>教材下載</IonLabel>
            </IonItem>*/}
            {/*<IonItem button
                     href="/language" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/language');
              }}>
              <IonLabel>語言</IonLabel>
            </IonItem>*/}
            <IonItem button
                     href="/setting" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/termsprivacy');
              }}>
              <IonLabel>隱私權及會員服務條款</IonLabel>
            </IonItem>
            {/*<IonItem button
                     href="/setting" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/about');
              }}>
              <IonLabel>關於懷相工人平台</IonLabel>
            </IonItem>*/}
            <IonItem>
              <IonLabel>登出</IonLabel>
            </IonItem>
          </IonList>

          <div className="serviceinfo">
            <h3>從懷疑到相信 | 福音課程</h3>
            <ul>
              <li>TEL : (02) 2752-5219</li>
              <li>FAX : (02) 2752-5181</li>
              <li>10690 台北市敦化南路一段161巷38號3樓</li>
            </ul>
            <div className="cy">&copy; 2019 Doubting to Believing All Right Reserved.</div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  allFiltered: selectors.sessions.allFiltered(state.sessions),
  favoritesFiltered: selectors.sessions.favoritesFiltered(state.sessions),
  searchText: state.sessions.searchText,
  favoriteSessions: state.sessions.favoriteSessions,
  filteredTracks: state.sessions.trackFilters,
  allTracks: selectors.sessions.allTracks(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

const mapDispatchToProps = {
  setSearchText: (searchText: string) => actions.sessions.setSearchText(searchText),
  updateTrackFilters: (trackList: string[]) => actions.sessions.updateTrackFilters(trackList)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Setting);
