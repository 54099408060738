import {
  IonButton,
  IonButtons,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonToast,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonBackButton,
  IonTitle, IonItemSliding, IonItemOptions, IonItemOption
} from '@ionic/react';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import SessionList from '../components/SessionList';
import SessionListFilter from '../components/SessionListFilter';
import {actions, RootState, selectors} from '../store';
import './Teachingmaterial.css';
import {share, logoVimeo, logoGoogleplus, logoTwitter, logoFacebook, options, cloudDownload} from 'ionicons/icons';
import {StaticEvent} from "../_/StaticEvent";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import _ from "../_";
import {AuthComponent} from "./_/AuthComponent";
import Moment from "react-moment";


type Props = RouteComponentProps<{}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

type State = {
  segment: string,
  isRefreshing: boolean,
  showLoading: boolean,
  showFilterModal: boolean,
  loadingMessage: string,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
};

const customActionSheetOptions = {
  header: '請選擇語言版本',
};

class Teachingmaterialdownload_tutorial extends AuthComponent<Props, State> {
  ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;
  ionFabRef: React.RefObject<HTMLIonFabElement>;

  constructor(props: Props) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.ionFabRef = React.createRef<HTMLIonFabElement>();

    this.state = {
      segment: 'all',
      isRefreshing: false,
      showLoading: false,
      showFilterModal: false,
      loadingMessage: '',

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }


  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/teachingmaterialdownload');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentFilter = () => {
    this.setState(() => ({
      showFilterModal: true
    }));
  };

  updateSearchTerm = (e: CustomEvent) => {
    this.props.setSearchText(e.detail.value);
  };

  openSocial = (network: string) => {
    this.setState(() => ({
      loadingMessage: `Posting to ${network}`,
      showLoading: true
    }));

    setTimeout(() => {
      this.setState(() => ({showLoading: false}));
    }, Math.random() * 1000 + 500);

    if (this.ionFabRef.current) {
      this.ionFabRef.current.close();
    }
  };

  updateSegment = (e: CustomEvent) => {
    this.setState(prevState => ({
      ...prevState,
      segment: e.detail.value
    }));
  };

  doRefresh = () => {
    setTimeout(() => {
      this.setState(() => ({isRefreshing: true}));
      if (this.ionRefresherRef.current) {
        this.ionRefresherRef.current.complete();
      }
    }, 500);
  };

  render() {

    let Items: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    if (pageData != null) {
      let its = pageData.List_Downloadable;

      if (its.length > 0) {
        Items = <>
          <IonContent>
            <IonList lines="full" className="downloadlink">
              {its.map(it => (
                <a href={it.href} key={it.href} target="_blank" download>
                  <IonItem detail>
                    <IonLabel>{it.name}</IonLabel>
                  </IonItem>
                </a>
              ))}
            </IonList>
          </IonContent>
        </>;
      } else {
        Items = <>
        </>;
      }

      return (
        <>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonBackButton defaultHref="/" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (pageData && pageData.Item_對課) {
                    this.props.history.replace(`/curriculum_tutorial/${pageData.Item_對課.對課Id}`);
                  } else {
                    this.props.history.goBack();
                  }
                }} text="返回"/>
              </IonButtons>
              <IonTitle>選擇版本</IonTitle>
            </IonToolbar>
          </IonHeader>
          {Items}
        </>
      );

    } else {

    }

    return <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.history.goBack();
            }} text="返回"/>
          </IonButtons>
          <IonTitle>選擇版本</IonTitle>
        </IonToolbar>
      </IonHeader>
      {Items}
    </>;
  }
}

const mapStateToProps = (state: RootState) => ({
  allFiltered: selectors.sessions.allFiltered(state.sessions),
  favoritesFiltered: selectors.sessions.favoritesFiltered(state.sessions),
  searchText: state.sessions.searchText,
  favoriteSessions: state.sessions.favoriteSessions,
  filteredTracks: state.sessions.trackFilters,
  allTracks: selectors.sessions.allTracks(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

const mapDispatchToProps = {
  setSearchText: (searchText: string) => actions.sessions.setSearchText(searchText),
  updateTrackFilters: (trackList: string[]) => actions.sessions.updateTrackFilters(trackList)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teachingmaterialdownload_tutorial);
