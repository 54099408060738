import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonTextarea
} from '@ionic/react';
import './Studentdatainputstep3.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent
}

class Studentdatainputstep3 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null
    };
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/studentdatainputstep2" onClick={() => this.props.history.goBack()}
                             text="返回"/>
            </IonButtons>
            <IonTitle>新增對象</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div className="titlebar">
            <h3>其他資訊</h3>
          </div>
          <form noValidate>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">與工人關係</IonLabel>
                <IonInput name="" type="text" required placeholder="請輸入"></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">其他說明</IonLabel>
                <IonTextarea rows={6} placeholder="請輸入說明"></IonTextarea>
              </IonItem>
            </IonList>
          </form>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton expand="block" color="primary" type="submit" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentlistst');
              }}>
              送出
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Studentdatainputstep3);
