import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent, IonItemSliding, IonItemOptions, IonItemOption
} from '@ionic/react';
import './Studentmanagement.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, create, ribbon, addCircleOutline, removeCircleOutline} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import Moment from 'react-moment';

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}

class Studentmanagement extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };

  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/studentmanagement');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  render() {
    let Content: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    if (pageData != null) {
      let {Item_對象, Value_已完成對課} = this.state.pageData;

      var it:any = Item_對象;
      if (it === null) {
        alert('嚴重錯誤');
        return;
      }


      //<editor-fold desc="Card1" defaultstate="collapsed" >
      let Card1 = null;
      {
        let CardContent = null;

        if (it.進教會時間 != null) {
          CardContent = <>
            <IonCardContent>
              <IonItem lines="none" className="def_item">
                <IonIcon icon={ribbon} slot="start"></IonIcon>
                <IonLabel>
                  <h3>進入教會</h3>
                  <p><Moment format="YYYY/MM/DD" date={it.進教會時間}/></p>
                </IonLabel>
              </IonItem>
            </IonCardContent>
          </>
        } else if (it.受洗時間 != null) {
          CardContent = <>
            <IonCardContent>
              <IonItem lines="none" className="def_item">
                <IonIcon icon={ribbon} slot="start"></IonIcon>
                <IonLabel>
                  <h3>受洗</h3>
                  <p><Moment format="YYYY/MM/DD" date={it.受洗時間}/></p>
                </IonLabel>
              </IonItem>
            </IonCardContent>
          </>
        } else if (it.決志時間 != null) {
          CardContent = <>
            <IonCardContent>
              <IonItem lines="none" className="def_item">
                <IonIcon icon={ribbon} slot="start"></IonIcon>
                <IonLabel>
                  <h3>決志</h3>
                  <p><Moment format="YYYY/MM/DD" date={it.決志時間}/></p>
                </IonLabel>
              </IonItem>
            </IonCardContent>
          </>
        } else {
          CardContent = <>
            <IonCardContent>
              <IonItem lines="none" className="def_item">
                <IonIcon icon={addCircleOutline} slot="start"></IonIcon>
                <IonLabel>
                  回報對象的課後信仰狀況
                </IonLabel>
              </IonItem>
            </IonCardContent>
          </>
        }


        Card1 = <>
          <IonCard button
                   href={`/studentsituationaftercurriculum/${it.對象Id}`} onClick={
            (e) => {
              e.preventDefault();
              this.props.history.push(`/studentsituationaftercurriculum/${it.對象Id}`);
            }}>
            <div className="cubeline cb_blue"></div>
            <IonCardHeader>
              <IonCardSubtitle>決志 / 受洗 / 進教會</IonCardSubtitle>
              <IonCardTitle className="hightlighttitle2"><span className='IT_name'>{it.姓名}</span> 課後信仰狀態</IonCardTitle>
            </IonCardHeader>
            {CardContent}
          </IonCard>
        </>;
      }
      //</editor-fold>

      //<editor-fold desc="Card2" defaultstate="collapsed" >
      let Card2 = null;
      {
        let CardContent = null;

        if (it.課程中止時間 != null) {
          CardContent = <>
            <IonItem lines="none" className="def_item">
              <IonIcon src="/assets/icon/pause.svg" slot="start"></IonIcon>
              <IonLabel>
                <p><Moment format="YYYY/MM/DD" date={it.課程中止時間}/></p>
                <p>{it.課程中止原因}</p>
              </IonLabel>
            </IonItem>
          </>
        } else {
          CardContent = <>
            <IonItem lines="none" className="def_item">
              <IonIcon icon={addCircleOutline} slot="start"></IonIcon>
              <IonLabel>
                回報對象課程中止的時間與原因
              </IonLabel>
            </IonItem>
          </>
        }


        if (Value_已完成對課) {
          Card2 = <>
            <IonCard button>
              <IonCardHeader>
                <IonCardTitle>課程中止</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonLabel>
                  課程已完結
                </IonLabel>
              </IonCardContent>
            </IonCard>
          </>;
        } else {
          Card2 = <>
            <IonCard button
                     href={`/studentcurriculumstop/${it.對象Id}`} onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push(`/studentcurriculumstop/${it.對象Id}`);
              }}>
              <IonCardHeader>
                <IonCardTitle>課程中止</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                {CardContent}
              </IonCardContent>
            </IonCard>
          </>;
        }
      }

      let Card3 = null;

        Card3 = <>
         <IonCard button
                   href={`/studentdataedit/${it.對象Id}`} onClick={
            (e) => {
              e.preventDefault();
              this.props.history.push(`/studentdataedit/${it.對象Id}`);
            }}>
            <div className="cubeline cb_green"></div>
            <IonCardHeader>
            <IonCardSubtitle>編輯 / 修改</IonCardSubtitle>
              <IonCardTitle ><span className='IT_name'>{it.姓名}</span> 基本資料</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines="none" className="def_item">
                <IonIcon src="/assets/icon/reader-outline.svg"  slot="start"></IonIcon>
                <IonLabel>
                   <p className="item_info"><span>{it.性別}</span><span>{it.年齡區間}</span>{/* /{it.課前信仰背景} */}</p>
                </IonLabel>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </>
      //</editor-fold>

      Content = <>
        {/* <div className="stinfobar">
          <div className="txt">{it.性別}</div>
          <div className="txt">{it.年齡區間}</div>
          <div className="txt">{it.課前信仰背景}</div>
        </div> */}

        {Card3}
        {Card1}
        {Card2}
        <IonButton expand="block" fill="clear" color="danger" className="hide">刪除對象</IonButton>
      </>;
    }


    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            {this.state.pageData &&
            <IonTitle>{it.姓名}</IonTitle>
            }

            {this.state.pageData &&
            <IonButtons slot="end">
              <IonButton href={`/studentdataedit/${it.對象Id}`} onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push(`/studentdataedit/${it.對象Id}`);
                }}>
                <IonIcon slot="icon-only" src="/assets/icon/pencil.svg" className="pedit"></IonIcon>
              </IonButton>
            </IonButtons>
            }
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="stmgr" color="light" scroll-y="false">
          {Content}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Studentmanagement);
