import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonAlert, IonItemSliding, IonItemOptions, IonItemOption
} from '@ionic/react';
import './Curriculumlist.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, addCircleOutline} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import Moment from 'react-moment';
import {StaticEvent} from "../_/StaticEvent";
import ReactHtmlParser from 'react-html-parser';
import {BaseComponent} from "./_/BaseComponent";
import {StorageService} from "../_/StorageService";


type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  tabName: string,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}


class Logout extends BaseComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      tabName: 'a',

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();
    await this._loadPageData();
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async _loadPageData() {
    await Static.storageService.clearLoggedAsync();
    // @ts-ignore
    window.location.href = '/';
  }

  render() {
    return (
      <>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
});

export default connect(
  mapStateToProps
)(Logout);
