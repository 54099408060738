//import createHistory from './history/createBrowserHistory';
//import createHistory from './history/createHashHistory';
//import i18n from './i18n';

declare var window: any;

var sharedStateManager = {
  APP_MODE: 'dev',
  subNav: null,
  rootNav: null,
  subTabs: null,
  cd: null,
  history: null,
};

var hasFirst = false;

var isForward = false;
var records = {};
var lastPath = null;


var randId = function () {
  return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
};

var utilManager = {
  sleepAsync: (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  },

  coalesce: (...args) => {
    for (let v of args) {
      if (v === undefined || v === null || v === 0) {
        /* do nothing */
      } else {
        return v;
      }
    }
  },

  randomStringID: (length, chars) => {
    let result = '';
    if (chars == undefined)
      chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  },


  /**
   * Construct given namespace on variable
   * for example:
   *  namespace(objA, 'propA.propB') ,  objA.propA ,  objA,propB will be created if not already exist
   * @param variable
   * @param ns  namespace path
   */
  namespace: (variable, ns) => {
    let sofar = variable;

    ns.split('.').forEach(function (e, i) {
      if (typeof sofar[e] === "undefined") {
        sofar[e] = {};
      }
      sofar = sofar[e];
    });

    return sofar;
  },

  /**
   * Check variable defines given namspace path
   * for example:
   *  exist(objA, 'propA.propB') checkes whether objA.propA.propB defined
   * @param variable
   * @param ns  namespace path
   */
  exist: (variable, ns) => {
    if (variable === null) {
      return false;
    }
    if (variable === undefined) {
      return false;
    }

    let sofar = variable;

    let splits = ns.split('.');
    for (let i = 0; i < splits.length; ++i) {
      if (sofar === null) {
        return false;
      }
      if (typeof sofar[splits[i]] === "undefined") {
        return false;
      }
      sofar = sofar[splits[i]];
    }
    return true;
  },

  /**
   * Check variable defines given namspace path
   * for example:
   *  exist(objA, 'propA.propB') checkes whether objA.propA.propB defined
   * @param variable
   * @param ns  namespace path
   */
  existGet: (variable, ns, defaultValue = null) => {
    if (variable === null) {
      return defaultValue;
    }
    if (variable === undefined) {
      return defaultValue;
    }

    let sofar = variable;

    let splits = ns.split('.');
    for (let i = 0; i < splits.length; ++i) {
      if (sofar === null) {
        return defaultValue;
      }
      if (typeof sofar[splits[i]] === "undefined") {
        return defaultValue;
      }
      sofar = sofar[splits[i]];
    }
    return sofar;
  },


  isIn: (array, element) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i] == element) {
        return true;
      }
    }
    return false;
  },

  /**
   * Remove first occurrence
   * @param array Only array is supported
   * @param element
   */
  removeFirst: (array, element) => {
    if (array === null) {
      return array;
    }

    if (!utilManager.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        if (array[i] == element) {
          return array.splice(i, 1);
        }
      }
    } else {
      throw 'unsupported';
    }
  },

  /**
   * Remove and return all occurrences
   * @param array Only array is supported
   * @param element
   */
  remove: (array, element) => {
    if (array === null) {
      return array;
    }

    if (!utilManager.isArray(array)) {
      let removed = [];

      for (let i = 0; i < array.length;) {

        if (array[i] == element) {
          removed.push(array.splice(i, 1));
          continue;
        }

        i++;
      }
      return removed;
    } else {
      throw 'unsupported';
    }
  },

  /**
   * Return true if value is an empty array, or an empty string, return given value otherwise
   * @param value  Array or String
   */
  isEmpty:(value) => {
    if (value == null)
      return null;

    if (utilManager.isArray(value)) {
      if (value.length === 0) {
        return true;
      } else {
        return false;
      }
    }

    if (utilManager.isString(value)) {
      if (value.length === 0) {
        return true;
      } else {
        return false;
      }
    }

    return value;
  },

  isString:(obj) => {
    return (typeof obj === 'string' || obj instanceof String)
  },

  isArray:(obj) => {
    return Array.isArray(obj);
  },

  isObject: (obj) => {
    let type = typeof obj;
    return type === 'function' || type === 'object' && !!obj;
  },

  /**
   * This does a deep cloned copy without checking for circular references.
   * @param src
   */
  iterationCopy: (src) => {
    let target = {};
    for (let prop in src) {
      if (src.hasOwnProperty(prop)) {
        // if the value is a nested object, recursively copy all it's properties
        if (utilManager.isObject(src[prop])) {
          target[prop] = utilManager.iterationCopy(src[prop]);
        } else {
          target[prop] = src[prop];
        }
      }
    }
    return target;
  }
};


var debugManager = {
  error: (errors) => {
    if (sharedStateManager.APP_MODE === 'dev' || sharedStateManager.APP_MODE === 'prod') {
      console.error(errors);
    } else {
      // nothing
    }
  },
  warn: (warning) => {
    if (sharedStateManager.APP_MODE === 'dev' || sharedStateManager.APP_MODE === 'prod') {
      console.warn(warning);
    } else {
      // nothing
    }
  },
  log: (logs) => {
    if (sharedStateManager.APP_MODE === 'dev' || sharedStateManager.APP_MODE === 'prod') {
      console.log(logs);
    } else {
      // nothing
    }
  },
};

var rxjs = {
  lastValueFrom<T>(source/*: Observable<T>*/) {
    return new Promise<T>((resolve, reject) => {
      let _hasValue = false;
      let _value: T;
      source.subscribe({
        next: value => {
          _value = value;
          _hasValue = true;
        },
        error: reject,
        complete: () => {
          if (_hasValue) {
            resolve(_value);
          } else {
            reject('error');
          }
        },
      });
    });
  }

}


var G = Function('return this')();
//G.test.history = history;
G.sharedStateManager = sharedStateManager;
window.G = G;

// var _global = (0, eval)('this');
// _global.location = {};
// _global.window.location = {};

var custom = {
  error: (errors) => {
    if (sharedStateManager.APP_MODE === 'dev' || sharedStateManager.APP_MODE === 'prod') {
      console.error(errors);
    } else {
      // nothing
    }
  },
  lessionName(第幾課: any) {
    if (第幾課 !== undefined && 第幾課 !== null) {
      let ret = '';
      if (第幾課 === 0) {
        ret = "課簡介";
      } else if (第幾課 >= 0) {
        ret = `第${第幾課}課`;
      }
      return ret;
    }
    else {
      return '';
    }
  }
};

export default {
  G,
  historyManager: {
    //history,
    isForward,
    hasFirst,
    records,
    randId,
  },
  sharedStateManager,
  utilManager,
  debugManager,
  custom,
  rxjs,
};


