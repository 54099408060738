import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent
} from '@ionic/react';
import './Articlecontent.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {StaticEvent} from "../_/StaticEvent";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import _ from "../_";
import {AuthComponent} from "./_/AuthComponent";
import Moment from "react-moment";
import ReactHtmlParser from 'react-html-parser';

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

}

class Articlecontent extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,
    };
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/article');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  render() {

    let Items: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    if (pageData != null) {
      let it = pageData.Item_文章;

      const re = /\s*(?:,|$)\s*/;
      const coverList = [...(it.封面組 +(",")).split(re),""];

      if (it !== null) {
        Items = <>
		<div className='arx'>
		<div className='topcontent'>
			<div className='topbox'>
          <div className='photobox'>
            <img src={ApiService.urlImageMapping("~/" + coverList[0])}/>
          </div>
          <div className='topic'>
            <h3>{it.標題}</h3>
            <span><Moment
              format="YYYY/MM/DD">{it.CreatedAt}</Moment></span><span>{it.__文章類別名稱}</span><span>{it.作者}</span>
          </div>
		  </div>
		  </div>
          <div className='atcontent'>
            {ReactHtmlParser(it.內容)}
          </div>
		  </div>
        </>;
      } else {
        Items = <>
          No Data
        </>;
      }
    }

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.goBack()} text="返回"/>
            </IonButtons>
            <IonTitle>全球見證</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className='atcontent-page'>
          {Items}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Articlecontent);
