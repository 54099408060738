import {BaseComponent} from "./BaseComponent";


import _ from '../../_';
import {Static} from '../../_/Static';
import {ApiService} from "../../_/ApiService";
import {UserLoggedInData} from "../../_/StorageService";

declare var window: any;

export class AuthComponent<P, S> extends BaseComponent<P,S> {

    public _userData: UserLoggedInData = null;
    public _isLoggedIn: boolean = false;

    constructor(props?: any) {
        super(props);
    }

    async authCheckAsync() {
        // AUTH USER SPEC
        // if (!(await Static.storageService.isLoggedAsync())) {
        //     this._userData = null;
        //     this._isLoggedIn = false;
        //     return;
        // }
        let userData = (await Static.storageService.getLoggedAsync());
        this._userData = userData;
        if (this._userData !== null) {
            this._isLoggedIn = true;
        } else {
            this._isLoggedIn = false;
        }
    }


    async componentDidMount() {
        await super.componentDidMount();
        await this.authCheckAsync();

        if (!this._isLoggedIn) {
            this._doLoadingPageDataInDidMount = true;

            //_.sharedStateManager.history.push('/login');
            window.location.href = '/login';
            //this.props.history.push('/index')
        }
    }

    async componentWillUnmount() {
        await super.componentWillUnmount();
    }

}
