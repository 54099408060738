import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonAlert
} from '@ionic/react';
import './QA.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, addCircleOutline} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent
}

class QA extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null
    };
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.goBack()} text="返回"/>
            </IonButtons>
            <IonTitle>工人常見問題</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="quandan-page" color="light">
          <h3>第一類 有關“逐字稿內容多、重複”</h3>
          <IonList lines="none" color="light">
            <IonItem color="light">
              <IonLabel className="ion-text-wrap">
                <div className="qusty">
                  <div className="qtxt">Q1.</div>
                  逐字稿內容很多，壓力大。
                </div>
                <div className="ans">
                  <div className="th">口語話照著唸並不多：</div>
<p>逐字稿是將老師課程中“口語”講的內容，打成文字，再加上圖表和程序，雖然看起來好像頁數很多，其實就像一位老師在帶領一位未信者，正在對他分享一樣，它的果效是可預期的。所以事先有按照裝備課所教導預備，一對一時照著唸逐字稿，內容不會多，許多工人實際一對一後，反而回應內容很豐富，從自己口中唸出，自己得到很大的造就。
工人有事先備課，一對一時大約每課2小時就能完成；其中有第四、五、六這3課有可能會花到2.5小時。</p>
                </div>
              </IonLabel>
            </IonItem>
            <IonItem color="light">
              <IonLabel className="ion-text-wrap">
                <div className="qusty">
                  <div className="qtxt">Q2.</div>
                  為何「逐字稿」許多內容重複之處？
                </div>
                <div className="ans">
                  <div className="th">會覺得「逐字稿」內容重覆，我們了解有幾個因素：</div>
                  <ol>
                    <li>我們發現大部分工人會遇到覺得重複的感覺，大多都是在”解圖”的時候，因為我們在解圖會分層次來解，譬如說：第一課年代表先解左邊創世右邊新天新地，還有末日整個拉開來，神用聖經總共1500年，年份講完後又說從創世到末世…到新天新地，這樣才處理完第一區塊。<br/>
那第二區塊又來了「為什麼書寫期間1500年，是幾年到幾年寫得呢？」就開始解西元前1400年然後西元96年，解完年代又開始解作者，摩西寫創世紀大概內容是什麼，神怎麼造了世界，還有怎麼造了人。
</li>
{/* <li>然後講啟示錄，是講到世界末了的訊息，這是約翰寫的。
作者講完了，接著再總結一次，又是1500年所有神要傳遞給人的訊息全都在裡面了！所以這樣一聽，坐在對面的對象就將內容立體起來了！<br/>
可是我們講了三次”1500年”，如果工人光看平面文字，短短的四五段講了三遍1500年，就會覺得重複，看不下去了！所以我們強調備課時要”一邊聽錄音檔一邊看逐字稿畫重點”是很重要的。
</li> */}
<li>另「第八課」因為這個內容是我們正在解圖8-2，所以可能有五段的文字，才解一張圖，那第一段要解圖的右邊，第二段可能解圖的左邊，第三段可能解圖的中間，所以在這五段裡面，通常都會看到同樣的一個焦點；<br/>
譬如說：這樣子(指圖)….這樣子(指圖)….就與神相通了！<br/>
等到第二段又來了：這樣子(指圖)…這樣子(指圖)…這樣子(指圖)….就與神相通了！<br/>
第三段又說：因為這樣(指圖)……就與神相通了！<br/>
可能連著五段都看到結尾說：”與神相通了！”<br/>
所以看逐字稿的文字會覺得：不是講一遍就好了嗎？<br/>
那是因為沒有實地去沙盤推演，一個一個的區塊來解圖。
</li>

                  </ol>
                </div>
              </IonLabel>
            </IonItem>
            <IonItem color="light">
              <IonLabel className="ion-text-wrap">
                <div className="qusty">
                <div className="qtxt">Q3.</div>
                  逐字稿中有許多“重複性”的內容問話，都需講給對方聽嗎？
                </div>
                <div className="ans">
                  <div className="th">有關逐字稿重複問話，分幾個方面說明如下：</div>
                  <ol>
                    <li>
                      <div className="sth">不知工人預備逐字講稿時，有沒有先讀過：</div>
                      <p>請帶領者一定<strong>要照逐字講稿</strong>念、問、講、解；同時第一遍預備時，<strong>必需讀後在逐字講稿上畫重點</strong>，才能將內容立體起來。</p>
                      </li>
                      <li>
                        <div className="sth">主題觀點需重複強調：</div>
                        <p>有的主軸在開始時講過了，經過層層解說完，之後會再回來重複收前面主軸的結尾，這是必要的重複！其實我們用口語在講時，別人聽起來不覺得重複，反覺得很清楚(若只看文字，就會有重複的感覺)，否則，講了後面，對方已經忘了前面的主軸在講什麼，所以有些“重複”是必要的。</p>
                      </li>
                      <li>
                        <div className="sth">實地推演一對一：</div>
                        <p>有一些內容是對方在讀講義或聖經後，帶領者用圖表來解說，解說的時候，又會提到剛剛講義中或聖經裡讀過的內容，所以帶領者需實際心中推演對象坐在對面，進行一對一，如此帶領者就不會覺得重複很多。</p>
                      </li>
                      <li>
                        <div className="sth">切勿因內容重複而刪減：</div>
                        <p>切勿因課程內容重複而濃縮，如此很可能將重要的“收尾”或者“說清楚”的結構刪除了，那對方聽起來會不清不楚。</p>
                      </li>
                    </ol>
                </div>
              </IonLabel>
            </IonItem>
          </IonList>

          <h3>第二類 有關“費時”</h3>
          <IonList lines="none" color="light">
            <IonItem color="light">
              <IonLabel className="ion-text-wrap">
                <div className="qusty">
                  <div className="qtxt">Q1.</div>
                  「懷相」每次對課需2小時，會太長嗎？
                </div>
                <div className="ans">
                  <div className="th">有關每課2小時，時間是否太長，說明如下：</div>
<ol>
                    <li>
                      <div className="sth">十次建立關係餵養生命：</div>
                      <p>很多成功使用工具的工人，他們從一開始就沒有想這方面的問題，順服著照用，同時禱告倚靠主，均能成功領人信主！這些工人反而覺得這十課的每次2小時，剛好可以與對象彼此建立關係，更容易使其穩定進入教會，而且剛好對方的生活難題可以逐漸藉著每課的真理，讓帶領者很容易地將其問題順利帶入真理上，教導對方如何倚靠主，是很好關係建立的經歷！</p>
                      </li>
                      <li>
                        <div className="sth">主題觀點需重複強調：</div>
                        <p>有的主軸在開始時講過了，經過層層解說完，之後會再回來重複收前面主軸的結尾，這是必要的重複！其實我們用口語在講時，別人聽起來不覺得重複，反覺得很清楚(若只看文字，就會有重複的感覺)，否則，講了後面，對方已經忘了前面的主軸在講什麼，所以有些“重複”是必要的。</p>
                      </li>
                      <li>
                        <div className="sth">「課程簡介」+「第一課」：</div>
                        <p>通常二者一起講會太長(約2.5小時)，尤其若是第一次一對一，帶領者較生澀，時間可能花得更長。所以裝備課時就有建議，若對象不熟識，可將「課程簡介」和「第一課」這二部份分開來講，第一次先以聊天的方式彼此認識並講「課程簡介」，下一次再上第一課，時間較為合宜。</p>
                      </li>
                      <li>
                        <div className="sth">課前有問題先寫下：</div>
                        <p>請對象在“前五課有問題先寫下來”，若這部份帶領者無法徹底實行，則課程時間容易拖長，較無法照預計的時間內完成。但若帶領者認為有需要回答，可能先要告知對方，這會影響課程時間；如果對方不介意，帶領者仍需在“前五課有問題先寫下來”的前提下，視情況來回答，否則會因對象問的問題而模糊焦點，且無法按時完成課程！</p>
                      </li>
                    </ol>
                </div>
              </IonLabel>
            </IonItem>
          </IonList>

          <h3>第三類 有關“一對一”</h3>
          <IonList lines="none" color="light">
            <IonItem color="light">
              <IonLabel className="ion-text-wrap">
                <div className="qusty">
                  <div className="qtxt">Q1.</div>
                  工人擔心帶領時，對象看著帶領者一直唸逐字稿，沒有互動很奇怪！
                </div>
                <div className="ans">
                  <div className="th">關於看逐字稿唸會不會沒有互動，說明如下：</div>
<ol>
                    <li>
                      <div className="sth">講義、聖經、圖表立體配搭：</div>
                      <p>其實在課程中，有許多地方會跟對方有互動的，例:請對方讀講義、聖經，帶領對方看圖表，逐字稿內因需堅立證據，會有要“問”對方的地方，其實這些就已經與對方有許多互動，所以不用太擔心!</p>
                      </li>
                      <li>
                        <div className="sth">課程有節奏地進行：</div>
                        <p>課程中很多地方，是帶領者一邊唸逐字講稿，一邊進行一段段的解經，對方是盯著聖經看，不會去注意帶領者是否在做什麼……。過去有許多工人初期還沒開始時，會有這種想法，但真的實際出去一對一時，對方回應都很好，所以無需擔心!</p>
                      </li>
                      <li>
                        <p>老師在裝備課時有教導，一定要預備，照著唸逐字稿，一點也不會怪，且若不照逐字稿唸，多講了其他新朋友不懂的真理及神學論，新朋友會覺得我們在歌功頌德，造成反效果反而不好！(可舉例：蕭韻-照著唸逐字稿，科技大老仍信主)</p>
                      </li>
                    </ol>
                </div>
              </IonLabel>
            </IonItem>
            <IonItem color="light">
              <IonLabel className="ion-text-wrap">
                <div className="qusty">
                  <div className="qtxt">Q2.</div>
                  有人提出是否可一對二地帶領？
                </div>
                <div className="ans">
                  <div className="th">採”一對一”方式來帶領較合宜，什麼情況可採”一對二”說明如下：</div>
<ol>
                    <li>
                      <p>如果對象是「夫妻、親兄弟姊妹，或情侶」，是可以安排”一對二”，不過夫妻、情侶之間相處上如有問題，仍建議分開來個別一對一。</p>
                      </li>
                      <li>
                        <p>如果對象是慕道友與基督徒，建議分開來個別一對一，因若是這種情況一對多，會造成。</p>
                        <ol>
                          <li>
                            如有一方提出問題，但另一方原本沒問題，卻又去思考這提出的問題，可能製造更多疑問，會增加對課進度和難度。
                          </li>
                          <li>
                            依我們經驗來說，對於慕道友或者是未信者在一對多的情況下，他們較不易將課程上有想問的，或是生活上剛好遇到有關課程的問題，他可能也不好意思提出來詢問。
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <p>所以仍建議帶領者，仍採”一對一”方式來帶領最為合宜！</p>
                </div>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(QA);
