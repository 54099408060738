import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle
} from '@ionic/react';
import './Devmenu.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, closeCircleOutline, checkmarkCircleOutline} from 'ionicons/icons';

import _ from '../_';
import {RouteComponentProps} from "react-router";


type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>
type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,
  //history: any,
}


class Devmenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPopover: false,
      showPopoverEvent: null,
      //history: null,
    };
  }

  goToLink(e: MouseEvent) {
    if (!e.currentTarget) {
      return;
    }
    e.preventDefault();
    this.props.history.push((e.currentTarget as HTMLAnchorElement).href);
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }
  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null,
    }));
  }


  render() {

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Develop Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonPopover
          // @ts-ignore
          isOpen={this.state.showPopover}
          // @ts-ignore
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>
        <IonContent>
          <IonList>
            <IonItem href="/emailverify">
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Emailvertify Setting password and login</IonLabel>
            </IonItem>
            <IonItem href="/login">
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Login</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={closeCircleOutline} slot="start"></IonIcon>
              <IonLabel>Forgot password</IonLabel>
            </IonItem>
            <IonItem href="/index">
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Index</IonLabel>
            </IonItem>
            <IonItem button
                     href="/curriculumlist" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/curriculumlist');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Curriculum list</IonLabel>
            </IonItem>
            <IonItem button
                     href="/curriculumlistvalue" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/curriculumlistvalue');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Curriculum list has value</IonLabel>
            </IonItem>
            <IonItem button
                     href="/curriculummanagement" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/curriculummanagement');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Curriculum management</IonLabel>
            </IonItem>
            <IonItem button
                     href="/curriculum" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/curriculum');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Curriculum</IonLabel>
            </IonItem>
            <IonItem button
                     href="/curriculumsituationinput" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/curriculumsituationinput');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Curriculum situation input</IonLabel>
            </IonItem>
            <IonItem button
                     href="/studentlist" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentlist');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student list</IonLabel>
            </IonItem>
            <IonItem button
                     href="/studentlistst" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentlistst');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student list has value</IonLabel>
            </IonItem>

            <IonItem button href="/studentdatainputstep1" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentdatainputstep1');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student data input-step1</IonLabel>
            </IonItem>
            <IonItem
              button
              href="/studentdatainputstep2" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentdatainputstep2');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student data input-step2</IonLabel>
            </IonItem>
            <IonItem
              button
              href="/studentdatainputstep3" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentdatainputstep3');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student data input-step3</IonLabel>
            </IonItem>
            <IonItem button
                     href="/studentdataedit" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentdataedit');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student data edit</IonLabel>
            </IonItem>
            <IonItem button
                     href="/Studentmanagement" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/Studentmanagement');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student management</IonLabel>
            </IonItem>
            <IonItem button
                     href="/studentsituationaftercurriculum" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentsituationaftercurriculum');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student situation after curriculum</IonLabel>
            </IonItem>
            <IonItem button
                     href="/studentcurriculumstop" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentcurriculumstop');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Student curriculum stop</IonLabel>
            </IonItem>
            <IonItem button
                     href="/articlelist" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/articlelist');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Article list</IonLabel>
            </IonItem>
            <IonItem button
                     href="/articlecontent" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/articlecontent');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Article content</IonLabel>
            </IonItem>
            <IonItem button
                     href="/personalstatistics" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/personalstatistics');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Personal statistics</IonLabel>
            </IonItem>
            <IonItem button
                     href="/sendjob" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/Sendjob');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Send a job</IonLabel>
            </IonItem>
            <IonItem button
                     href="/qa" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/QA');
              }}>
              <IonIcon icon={closeCircleOutline} slot="start"></IonIcon>
              <IonLabel>QA</IonLabel>
            </IonItem>
            <IonItem button
                     href="/setting" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/setting');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Setting</IonLabel>
            </IonItem>
            <IonItem button
                     href="/personaldata" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/personaldata');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Personal data</IonLabel>
            </IonItem>
            <IonItem button
                     href="/teachingmaterial" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/teachingmaterial');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Teaching material</IonLabel>
            </IonItem>
            <IonItem button
                     href="/teachingmaterialdownload" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/teachingmaterialdownload');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Teaching material</IonLabel>
            </IonItem>
            <IonItem button
                     href="/language" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/language');
              }}>
              <IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>
              <IonLabel>Language</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={closeCircleOutline} slot="start"></IonIcon>
              <IonLabel>About DTB</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={closeCircleOutline} slot="start"></IonIcon>
              <IonLabel>Terms and privacy</IonLabel>
            </IonItem>
          </IonList>
          {/*總共27個頁面*/}
        </IonContent>
      </>
    );
  }
}

// const mapStateToProps = (state: RootState) => ({
//   conferenceDate: selectors.sessions.conferenceStart(state.sessions),
// });

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Devmenu);

