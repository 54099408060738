import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonSlides,
  IonSlide,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonBadge, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle
} from '@ionic/react';
import './Root.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, settings, bulb} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import _ from '../_';
import ReactHtmlParser from 'react-html-parser';
import {StaticEvent} from "../_/StaticEvent";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}

const slideOpts = {
  initialSlide: 1,
  speed: 400,
  loop: true,
  updateOnWindowResize: true,
};

class Root extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }


  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    // const id = this.props.match.params.id;
    // if (!id) {
    //   return; //ERROR INPUTS
    // }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/index');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };


  render() {
    let Items: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    if (this.state.pageData === null) {
      return <></>;
    }

    if (pageData != null) {
      //let its = pageData.List_文章;
      let its = pageData.List_工人板塊;

      if (its.length > 0) {
        Items = <>
          {its.map(it => {
            let Item_文章 = it._文章;
            const re = /\s*(?:,|$)\s*/;
            const coverList = [...(Item_文章.封面組 + (",")).split(re), ""];

            if (it.指定傳送網址) {
              return <>
                <IonSlide key={Item_文章.文章Id}>
                  <a href={it.指定傳送網址}>
                    <div className="slidebox">
                      <div className='photobox'>
                        <img src={ApiService.urlImageMapping("~/" + coverList[0])}/>
                      </div>
                      <div className='photoinfo'>
                        <h3>{Item_文章.標題}</h3>
                        <span>{Item_文章.__文章類別名稱}</span><span>{Item_文章.作者}</span>
                      </div>
                    </div>
                  </a>
                </IonSlide>
              </>;
            } else {
              return <>
                <IonSlide key={Item_文章.文章Id}>
                  <a href={`/articlecontent/${Item_文章.文章Id}`} onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push(`/articlecontent/${Item_文章.文章Id}`);
                    }}>
                    <div className="slidebox">
                      <div className='photobox'>
                        <img src={ApiService.urlImageMapping("~/" + coverList[0])}/>
                      </div>
                      <div className='photoinfo'>
                        <h3>{Item_文章.標題}</h3>
                        <span>{Item_文章.__文章類別名稱}</span><span>{Item_文章.作者}</span>
                      </div>
                    </div>
                  </a>
                </IonSlide>
              </>;
            }
          })}


        </>;
      } else {
        Items = <>
          No Data
        </>;
      }
    }

    return (
      <>
        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonMenuButton/>
            </IonButtons>
            <IonTitle>懷相工人雲端平台</IonTitle>
            {/*<IonButtons slot="end">
              <IonButton icon-only onClick={() => this.props.history.push('/setting')}>
                <IonIcon icon={settings} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>*/}
          </IonToolbar>
        </IonHeader>
        <IonContent
          className="index-page">
          <div className="mtpage">

            <div className="mtitem">

              <IonSlides>
                {Items}
              </IonSlides>
            </div>
            <div className="mtitem">
              <IonItem detail lines="full" className="useritembar" href="/personalstatistics" onClick={
                (e) => {
                  e.preventDefault();
                  this.props.history.push('/personalstatistics');
                }}>
                <IonLabel>
                  <div className="useritem">
                    <h2>{this.state.pageData.Item_會員.姓氏}{this.state.pageData.Item_會員.名字}</h2>
                    <div className="userinfodetail">
                      <span>目前對課人數</span><span className="amount">{this.state.pageData.Item_STATE.目前對課人數}</span>
                    </div>
                  </div>
                </IonLabel>
              </IonItem>
              <IonGrid className="sixcol">
                <IonRow>
                  <IonCol className="tob" size="12" size-md="12" align-self-stretch onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push('/curriculumlist');
                    }}>
                    <div className="mainbtn">
                      <div className="bxicon i_cm"></div>
                      <h3>工人對課自助管理</h3>
                    </div>

                  </IonCol>
                </IonRow>

                <IonRow className="plus_func_column">
                <IonCol size="4" size-md="12" align-self-stretch onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push('/curriculumlist_tutorial');
                    }}>
                    <div className="bxicon i_doc"></div>

                    下載逐字講稿</IonCol>
                  <IonCol size="4" size-md="12" align-self-stretch onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push('/articlelist_qptool');
                    }}>
                    <div className="bxicon i_tl"></div>

                    工人補給站</IonCol>
                    <IonCol size="4" size-md="12" align-self-stretch onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push('/articlecontent/1045');
                    }}>
                    <div className="bxicon i_video "></div>
                    工人平台教學</IonCol>
                  
                  <IonCol size="4" size-md="12" align-self-stretch onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push('/sendjob');
                    }}>
                    <div className="bxicon i_rw">
                      {/*<IonBadge color="danger">1</IonBadge>*/}
                    </div>
                    回覆接受派工
                  </IonCol>
                  <IonCol size="4" size-md="12" align-self-stretch onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push('/articlelist');
                    }}>
                    <div className="bxicon i_gi"></div>
                    全球見證</IonCol>
                  <IonCol size="4" size-md="12" align-self-stretch onClick={
                    (e) => {
                      e.preventDefault();
                      this.props.history.push('/qa');
                    }}>
                    <div className="bxicon i_qa "></div>
                    常見問題解答</IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions), isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Root);
