import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent, IonItemSliding, IonItemOptions, IonItemOption
} from '@ionic/react';
import './Curriculummanagement.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, addCircleOutline, contact, contacts, journal} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import Moment from 'react-moment';
import {StaticEvent} from "../_/StaticEvent";
import ReactHtmlParser from 'react-html-parser';

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  showAlert_6: boolean,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}


function styleNameList(nameList:string, className: string = null) {
  if (nameList == null || nameList == '')
    return;

  var output = (nameList).split(',');
  var output_with_qoutes = null;

  if (className === null) {
    output_with_qoutes = output.map((r) => {
      return `<span>${r}</span>`
    });
  } else {
    output_with_qoutes = output.map((r) => {
      return `<span class="${className}">${r}</span>`
    });
  }
  return ReactHtmlParser(output_with_qoutes.join(''));
}


class Curriculummanagement_tutorial extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      showAlert_6: false,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error([this.props.location,prevProps.location]);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //

    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/curriculummanagement');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  async handleDeleteSubmit(e: any) {
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_curriculummanagement_delete');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.props.history.push('/curriculumlist_tutorial');

          } else {
            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  async handleAddShow(e: any) {
    await this.setState(() => ({
      showAlert_6: true,
    }));
  }








  render() {
    let Content: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');


    let it: any;



    if (pageData != null) {
      it = pageData.Item_對課;

      //對象姓名、<span className="stop">已暫停對象姓名</span>
      if (it === null) {
        alert('嚴重錯誤');
        return;
      }



      //<editor-fold desc="Card1" defaultstate="collapsed" >
      let Card1 = null;
      let Card2 = null;
      if (it._對象人數 <= 0) {
        Card1 = <>
          <IonCard button
                   href="/" onClick={
            (e) => {
              e.preventDefault();
              this.props.history.push(`/studentlistst_tutorial/${it.對課Id}`);
            }}>
              <div className="cubeline cb_green"></div>
            <IonCardHeader>
            <IonCardSubtitle>對象管理 / 對象基本資料 / 對象課後信仰</IonCardSubtitle>
              <IonCardTitle>對象</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <IonItem lines="none" className="def_item">
                {/* <IonIcon icon={addCircleOutline} slot="start"></IonIcon>
                <IonLabel>
                  請填寫對象詳細資料
                </IonLabel> */}
              </IonItem>
            </IonCardContent>
          </IonCard>
        </>;

        <IonAlert
          isOpen={this.state.showAlert_6}
          onDidDismiss={() => this.setState(() => ({showAlert_6: false}))}
          header={'資料夾已建立'}
          message='請建立資料夾內的「對象基本資料」'
          buttons={[
            {
              text: '取消',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            },
            {
              text: '前往',
              handler: async (v) => {
                this.props.history.push(`/studentlistst_tutorial/${it.對課Id}`);
                await this.setState({
                  showAlert_6: false,
                });


              }
            }
          ]}
        />


      } else if (it._對象人數 >= 1) {

        //李艾芳、林芝蘭、<span className="otostop">Jason</span>

        Card1 = <>
          <IonCard button
                   href={`/studentlistst_tutorial/${it.對課Id}`} onClick={
            (e) => {
              e.preventDefault();


              this.props.history.push(`/studentlistst_tutorial/${it.對課Id}`);
            }}>
              <div className="cubeline cb_green"></div>
            <IonCardHeader>
              <IonCardSubtitle>對象管理 / 對象基本資料 / 對象課後信仰</IonCardSubtitle>
              <IonCardTitle>對象</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <>
                <IonItem lines="none" className="def_item">
                  <IonIcon src="/assets/icon/mur.svg" slot="start"></IonIcon>
                  <IonLabel>
                    <div className="namelist">
                      {styleNameList(it._對象姓名_完結, 'started')}
                      {styleNameList(it._對象姓名_未完結, 'halt')}
                     </div>
                    <p>{it._對課類型}</p>
                  </IonLabel>
                </IonItem>
              </>
            </IonCardContent>
          </IonCard>
        </>;


Card2 = <>
  <IonCard button
           href={`/curriculum_tutorial/${it.對課Id}`} onClick={
    (e) => {
      e.preventDefault();

      if (it._對象人數 == 0) {
        alert('請先建立對象資料，才能進入對課紀錄');
        return;
      }
      this.props.history.push(`/curriculum_tutorial/${it.對課Id}`);
    }}>
    <div className="cubeline cb_red"></div>
    <IonCardHeader>
      <IonCardSubtitle>填寫對課日期 / 下載逐字講稿 / 對象反饋 / 代禱事項</IonCardSubtitle>
      <IonCardTitle className="hightlighttitle">對課紀錄</IonCardTitle>
    </IonCardHeader>

    <IonCardContent>
      {it._完結 == false && it._最後第幾課 === null &&
      <IonItem lines="none" className="def_item">
          <IonIcon icon={addCircleOutline} slot="start"></IonIcon>
          <IonLabel>
          請填入對象課後回饋和代禱事項
          </IonLabel>
      </IonItem>
      }
      {it._完結 == false && it._最後第幾課 !== null &&
      <>
          <IonItem lines="none" className="def_item">
              <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>
              <IonLabel>
                對課進度 {`第${it._最後第幾課}課`} <Moment format="YYYY/MM/DD">{it._最後上課時間}</Moment>
              </IonLabel>
          </IonItem>
          {/* <IonItem lines="none" className="def_item">
              <IonIcon src="/assets/icon/download-outline.svg" slot="start"></IonIcon>
              <IonLabel>
                逐字稿最新下載至 {`第${it._最後第幾課}課`}
          </IonLabel>
          </IonItem> */} {/*202208 新增規格*/}


      </>
      }
      {it._完結 == true &&
        <>
          <IonItem lines="none" className="def_item">
            <IonIcon src="/assets/icon/ribbon.svg" slot="start"></IonIcon>
            <IonLabel>
              已完成10課對課
            </IonLabel>
          </IonItem>

        </>
      }
    </IonCardContent>
  </IonCard>
</>;

      }
      //</editor-fold>

      //<editor-fold desc="Card2" defaultstate="collapsed" >

      //</editor-fold>

      Content = <>
      <div className='instructions'>
        <p>點擊下方“對象欄”，先填寫新對象基本資料，之後會告知如何下載「逐字講稿」</p>
          </div>

        {Card1}
        {Card2}
        <IonButton expand="block" fill="clear" color="danger" className="hide" onClick={
          (e) => {
            e.preventDefault();
            this.handleDeleteSubmit(e);
          }}>刪除資料夾</IonButton>
          <div className="txtcenter">
             <span>對課建立時間：<Moment format="YYYY/MM/DD hh:mm" date={it.CreatedAt}/></span>
          </div>


      </>;

    }

    return (
      <>

        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // this.props.history.goBack();
                this.props.history.replace(`/curriculumlist_tutorial`);
              }} text="返回"/>
            </IonButtons>
            {this.state.pageData &&
            <IonTitle>{it.案件名稱} <span>（資料夾）</span></IonTitle>
            }
          </IonToolbar>
        </IonHeader>

        <IonContent color="light" className="cardctrl">
          {Content}

        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Curriculummanagement_tutorial);
