import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent
} from '@ionic/react';
import './Studentsituationaftercurriculum.css';
import AboutPopover from '../components/AboutPopover';
import {addCircleOutline, calendar, pin, ribbon, checkmark} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import Studentmanagement from "./Studentmanagement";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,
  pagePathname: string,

  Item_對象: any,
}

const customActionSheetOptions1 = {
  header: '請選擇教會',
};

class Studentsituationaftercurriculum extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,
      pagePathname: this.props.location.pathname,

      Item_對象: {},
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/studentsituationaftercurriculum');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });
            await this.setState({
              Item_對象: this.state.pageData.Item_對象
            })

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          _.debugManager.warn(err);

          //alert('網路連線有問題');
          const controller: any = document.querySelector('ion-toast-controller');
          let msg = await controller.create({
            message: `網路連線有問題`,
            duration: 2000,
            position: 'top'
          });
          await msg.present();
        }
      );
  }

  handleInputChange(e) {
    let newItem = Object.assign({}, this.state.Item_對象, {
      [e.target.name]: e.target.value,
    });

    // @ts-ignore
    this.setState({
      Item_對象: newItem,
    });
  }

  async handleSaveSubmit(e: any) {
    e.preventDefault();

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: this.state.Item_對象,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_studentsituationaftercurriculum');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS
            this.props.history.goBack();
            //await this._loadPageData();

          } else {
            alert(error.message);
            // const controller: any = document.querySelector('ion-toast-controller');
            // let msg = await controller.create({
            //   message: error.message,
            //   duration: 2000,
            //   position: 'top'
            // });
            // await msg.present();
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  render() {
    let selectyear = String((new Date().getFullYear()) + 1);
    let Content: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    if (pageData != null) {
      var it = pageData.Item_對象;

      if (it === null) {
        alert('嚴重錯誤');
        return;
      }

      let is已決志 = this.state.Item_對象.課前信仰背景 === "基督徒" && (this.state.Item_對象.課前信仰狀況_信 === "已決志" || this.state.Item_對象.課前信仰狀況_信 === "已受洗");
      let is已受洗 = this.state.Item_對象.課前信仰背景 === "基督徒" && (this.state.Item_對象.課前信仰狀況_信 === "已受洗");

      Content = <>
        {is已決志 &&
        <React.Fragment>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>決志</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines="none">
                <IonIcon icon={checkmark} slot="start" color="success"/>
                課前已決志
              </IonItem>
            </IonCardContent>
          </IonCard>
        </React.Fragment>
        }
        {!is已決志 &&
        <React.Fragment>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>決志</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines="none" className="def_item">
                <IonIcon icon={calendar} slot="start" color="danger"></IonIcon>
                <IonLabel>
                  <IonDatetime
                    name="決志時間"
                    value={this.state.Item_對象.決志時間}
                    onIonChange={(e: any) => this.handleInputChange(e)}

                    min="1990-02"
                    max= {selectyear}
                    displayFormat="YYYY/MM/DD" placeholder="請選擇日期" done-text="確定" cancel-text="取消"></IonDatetime>
                </IonLabel>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </React.Fragment>
        }

        {is已受洗 &&
        <React.Fragment>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>受洗</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines="none">
                <IonIcon icon={checkmark} slot="start" color="success"/>
                課前已受洗
              </IonItem>
            </IonCardContent>
          </IonCard>
        </React.Fragment>
        }
        {!is已受洗 &&
        <React.Fragment>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>受洗</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines="none" className="def_item">
                <IonIcon icon={calendar} slot="start" color="danger"></IonIcon>
                <IonLabel>
                  <IonDatetime
                    name="受洗時間"
                    value={this.state.Item_對象.受洗時間}
                    onIonChange={(e: any) => this.handleInputChange(e)}

                    min="1990-02"
                    max= {selectyear}
                    displayFormat="YYYY/MM/DD" placeholder="請選擇日期" done-text="確定" cancel-text="取消"></IonDatetime>
                </IonLabel>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </React.Fragment>
        }


        <IonCard>
          <IonCardHeader>
            <IonCardTitle>進教會</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem lines="none" className="def_item">
              <IonIcon icon={calendar} slot="start" color="danger"></IonIcon>
              <IonLabel>
                <IonDatetime
                  name="進教會時間"
                  value={this.state.Item_對象.進教會時間}
                  onIonChange={(e: any) => this.handleInputChange(e)}

                  min="1990-02"
                  max= {selectyear}
                  displayFormat="YYYY/MM/DD" placeholder="請選擇日期" done-text="確定" cancel-text="取消"></IonDatetime>
              </IonLabel>
            </IonItem>
            {this.state.Item_對象.進教會時間 != null &&
            <IonItem>
              <IonLabel position="stacked">進入教會 <span>*</span></IonLabel>
              <IonSelect name="進教會的教會"
                         value={this.state.Item_對象.進教會的教會 != null ? this.state.Item_對象.進教會的教會 : ''}
                         onIonChange={(e: any) => this.handleInputChange(e)}
                         interfaceOptions={customActionSheetOptions1}
                         interface="action-sheet" placeholder="請選擇" okText="確認" cancelText="取消">
                <IonSelectOption value="">請選擇</IonSelectOption>
                <IonSelectOption value="與工人同教會">與工人同教會</IonSelectOption>
                <IonSelectOption value="其他教會">其他教會</IonSelectOption>
              </IonSelect>
            </IonItem>}
            {this.state.Item_對象.進教會的教會 === "其他教會" &&
            <IonItem>
              <IonLabel position="stacked">其他教會名稱 <span>*</span></IonLabel>
              <IonInput name="進教會的教會_其他"
                        value={this.state.Item_對象.進教會的教會_其他}
                        onIonChange={(e: any) => this.handleInputChange(e)} type="text" required
                        placeholder="請輸入其他教會的名稱"></IonInput>
            </IonItem>
            }
          </IonCardContent>
        </IonCard>

      </>;
    }

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            <IonTitle>課後信仰狀態</IonTitle>
            {this.state.pageData &&
            <IonButtons slot="end">
              <IonButton onClick={e => this.handleSaveSubmit(e)}>
                儲存
              </IonButton>
            </IonButtons>
            }
          </IonToolbar>
        </IonHeader>
        <IonContent className="aftercs inputform" color="light">
          {Content}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated
});


export default connect(
  mapStateToProps
)(Studentsituationaftercurriculum);
