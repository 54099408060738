import {ApiService} from "./ApiService";
import {StorageService} from "./StorageService";
import {WsClient} from "./WsClient";

const axios = require('axios');

export class Static {

    // public static wsClient: WsClient = (() => {
    //     let wsClient = new WsClient();
    //     //wsClient.startAsync();  //intentionally does not await
    //     return wsClient;
    // })();

    // public static browserNotifyAsync = (() => {
    //     Notification.requestPermission();
    //
    //     return (message) => {
    //         return Notification.requestPermission().then((result) => {
    //             if (result === "granted") {
    //                 var notification = new Notification(message);
    //             }
    //         });
    //     };
    // })();

    public static storageService: StorageService = new StorageService();

    public static apiService: ApiService = (() => {
        let apiService = new ApiService();

        axios.interceptors.request.use(async (config) => {
                // Do something before request is sent
                var userData = (await Static.storageService.getLoggedAsync());

                if (userData == null) {

                } else {
                    Object.assign(config.data, {'session': userData.sessionId});
                }
                return config;
            },
            (error) => {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        return apiService;
    })();


    public static F1_StateStore: any = {};

    public static F2_StateStore: any = {};
}
