import React, {Component, FormEvent} from 'react';
import {
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonCheckbox,
} from '@ionic/react';
import './Login.css';
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import _ from '../_';
import ReactHtmlParser from 'react-html-parser';
import {StaticEvent} from "../_/StaticEvent";

type State = {
  username: string | null,
  password: string | null
}

export default class Login extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      username: '',
      password: '',
    }
  }

  async handleSubmit(e: any) {
    e.preventDefault();

    // @ts-ignore
    const {username, password} = this.state;

    // @ts-ignore
    //const {dispatch} = this.props;
    //dispatch(userActions.login(username, password));

    if (username && password) {
      // WAITING SCREEN
      StaticEvent.LoadingEvent.next(true);

      // POST
      let postData = {
        account: username,
        password: password,
        timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
      };
      const postUrl = ApiService.urlMapping('~/api/1/_login');
      Static.apiService
        .post(postUrl, postData)
        .subscribe(async (res) => {
            // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

            let data = res.data;
            _.debugManager.log(data);

            let error = data.error || null;
            let result = error == null;

            if (result) { // SUCEESS

              let t_customer = data.payload.userEntity;
              let serverSessionId = data.payload.SessionID;

              await Static.storageService.setLoggedAsync(serverSessionId, JSON.stringify(t_customer));


              // @ts-ignore
              this.props.history.push('/')

              //window.location = '/f1';
              //history.pushState({}, 'page 2', '/f1');
              //await _.sharedStateManager.rootNav.setRoot(askMeIndex, {});
              //await this.viewCtrl.dismiss();

            } else {
              alert(error.message);
              // const controller: any = document.querySelector('ion-toast-controller');
              // let msg = await controller.create({
              //   message: error.message,
              //   duration: 2000,
              //   position: 'top'
              // });
              // await msg.present();
            }
          }, async (err) => {
            // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
            _.debugManager.warn(err);
            alert('網路連線有問題');
          }
        );

    }
  }

  handleInputChange(e) {
    // @ts-ignore
    this.setState({
      [e.target.name]: e.target.value
    });
  }


  updateUserName(e: CustomEvent) {
  }

  logInUser() {
  }

  signUpUser() {
  }

  render() {
    return (
      <>
        {ReactHtmlParser('<ion-toast-controller></ion-toast-controller>')}
        <div className="logo">
          <div className="ilogo"></div>
          <div className="plustitle">懷相工人雲端平台</div>
          <div className="main_logo">
            <div className="bx1"></div>
            <div className="bx2"></div>
            <div className="bx3"></div>
            <div className="bx4"></div>
            <div className="bx5"></div>
            <div className="bx6"></div>
            <div className="bx7"></div>
            <div className="bx8"></div>
            <div className="bx9"></div>
            <div className="bx10"></div>
            <div className="bx11"></div>
            <div className="bx12"></div>
            <div className="bx13"></div>
            <div className="bx14"></div>
            <div className="bx15"></div>
            <div className="bx16"></div>
            <div className="bx17"></div>
            <div className="bx18"></div>
            <div className="bx19"></div>
            <div className="bx20"></div>
            <div className="bx21"></div>
            <div className="bx22"></div>
            <div className="bx23"></div>
            <div className="bx24"></div>
            <div className="bx25"></div>
            <div className="bx26"></div>
            <div className="bx27"></div>
            <div className="bx28"></div>
            <div className="bx29"></div>
            <div className="bx30"></div>
            <div className="bx31"></div>
            <div className="bx32"></div>
            <div className="bx33"></div>
            <div className="bx34"></div>
            <div className="bx35"></div>
            <div className="bx36"></div>
            <div className="bx37"></div>
            <div className="bx38"></div>
            <div className="bx39"></div>
            <div className="bx40"></div>
            <div className="bx41"></div>
            <div className="bx42"></div>
            <div className="bx43"></div>
            <div className="bx44"></div>
            <div className="bx45"></div>
            <div className="bx46"></div>
            <div className="bx47"></div>
            <div className="bx48"></div>
            <div className="bx49"></div>
            <div className="bx50"></div>
            <div className="bx51"></div>
            <div className="bx52"></div>
            <div className="bx53"></div>
            <div className="bx54"></div>
            <div className="bx55"></div>
            <div className="bx56"></div>
            <div className="bx57"></div>
            <div className="bx58"></div>
            <div className="bx59"></div>
            <div className="bx60"></div>
            <div className="bx61"></div>
            <div className="bx62"></div>
            <div className="bx63"></div>
            <div className="bx64"></div>
            <div className="bx65"></div>
            <div className="bx66"></div>
            <div className="bx67"></div>
            <div className="bx68"></div>
            <div className="bx69"></div>
            <div className="bx70"></div>
            <div className="bx71"></div>
            <div className="bx72"></div>
            <div className="bx73"></div>
            <div className="bx74"></div>
            <div className="bx75"></div>
            <div className="bx76"></div>
            <div className="bx77"></div>
            <div className="bx78"></div>
            <div className="bx79"></div>
            <div className="bx80"></div>
            <div className="bx81"></div>
            <div className="bx82"></div>
            <div className="bx83"></div>
            <div className="bx84"></div>
            <div className="bx85"></div>
            <div className="bx86"></div>
            <div className="bx87"></div>
            <div className="bx88"></div>
            <div className="bx89"></div>
            <div className="bx90"></div>
            <div className="bx91"></div>
            <div className="bx92"></div>
            <div className="bx93"></div>
            <div className="bx94"></div>
            <div className="bx95"></div>
            <div className="bx96"></div>
            <div className="bx97"></div>
            <div className="bx98"></div>
            <div className="bx99"></div>
            <div className="bx100"></div>
          </div>
        </div>
        <form noValidate className="loginform" onSubmit={e => this.handleSubmit(e)}>
          <IonList no-lines>
            <IonItem>
              <IonLabel position="floating">姓名</IonLabel>
              <IonInput
                name="username"
                value={this.state.username}  //改成 用戶姓名
                onInput={(e: any) => this.handleInputChange(e)}

                //onIonChange={this.updateUserName}
                type="text"
                placeholder="輸入報名時的姓名"
                autocapitalize="off"
                required></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">帳號</IonLabel>
              <IonInput
                name="password"
                value={this.state.password}  //改成 Email 或 手機
                onInput={(e: any) => this.handleInputChange(e)}
                placeholder="台灣\手機不加國碼，海外\小寫Email"
                type="text" required></IonInput>
            </IonItem>
          </IonList>

          <IonRow responsive-sm>
            <IonCol>
              <IonButton expand="block" color="primary" onClick={this.logInUser} type="submit">
                進入
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </>
    );
  }
}
