import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonListHeader,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonTextarea
} from '@ionic/react';
import './Emailverify.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, checkmark} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {StaticEvent} from "../_/StaticEvent";
import Studentmanagement from "./Studentmanagement";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  Item_會員: any,
  Dict_會員_派工時段: any,
  Dict_會員_語言: any,
}

const customActionSheetOptions1 = {
  header: '請選擇對象信仰背景',
};
const customActionSheetOptions2 = {
  header: '請選擇對象信仰狀況',
};

class Personaldataedit extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      Item_會員: {},
      Dict_會員_派工時段: {},
      Dict_會員_語言: {},
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/personaldataedit');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.loadPageData_OnlyOnceGuard = true;

            await this.setState({
              pageData: payload,
            });

            await this.setState({
              Item_會員: payload.Item_會員,
              Dict_會員_派工時段: payload.Dict_會員_派工時段,
              Dict_會員_語言: payload.Dict_會員_語言,
            });


          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  handleInputChange(e) {
    let newItem = Object.assign({}, this.state.Item_會員, {
      [e.target.name]: e.target.value,
    });

    this.setState({
      Item_會員: newItem,
    });
  }

  handleInputCheckedChange(e) {
    let newItem = Object.assign({}, this.state.Item_會員, {
      [e.target.name]: e.target.checked,
    });

    this.setState({
      Item_會員: newItem,
    });
  }

  handleInputCheckedChangeVal(e, name, val) {
    if (e.target.checked) {
      if (this.state.Item_會員[e.target.name] == val) {
        let newItem = Object.assign({}, this.state.Item_會員, {
          [e.target.name]: null,
        });

        this.setState({
          Item_會員: newItem,
        });

      } else {
        let newItem = Object.assign({}, this.state.Item_會員, {
          [e.target.name]: val,
        });

        this.setState({
          Item_會員: newItem,
        });
      }

    } else {
      this.setState({
        Item_會員: this.state.Item_會員,
      });
    }
  }

  handleTimeDictChange(e) {
    let newItem = Object.assign({}, this.state.Dict_會員_派工時段, {
      [e.target.name]: e.target.checked,
    });

    this.setState({
      Dict_會員_派工時段: newItem,
    });
  }

  handleLangDictChange(e) {
    let newItem = Object.assign({}, this.state.Dict_會員_語言, {
      [e.target.name]: e.target.checked,
    });

    this.setState({
      Dict_會員_語言: newItem,
    });
  }


  async handleSaveSubmit(e: any) {
    if (e != null) {
      e.preventDefault();
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);


    var test = this.state.Item_會員;

    // POST
    let postData = {
      p1: this.state.Item_會員,
      p2: this.state.Dict_會員_派工時段,
      p3: this.state.Dict_會員_語言,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_personaldataedit');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS
            this.props.history.goBack();
            //await this._loadPageData();

          } else {
            alert(error.message);
            // const controller: any = document.querySelector('ion-toast-controller');
            // let msg = await controller.create({
            //   message: error.message,
            //   duration: 2000,
            //   position: 'top'
            // });
            // await msg.present();
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  render() {
    let usebornyear = String((new Date().getFullYear()));
    console.dir(this.state.pageData);


    // {e => {
    //
    //   e.stopPropagation();
    //   // @ts-ignore
    //   this.refs.Form.getDOMNode().dispatchEvent(new Event("submit"));
    // }}

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            <IonTitle>個人資料及派工意願編輯</IonTitle>
            {this.state.pageData &&
              <IonButtons slot="end">
                <IonButton onClick={e => this.handleSaveSubmit(e)}>
                  儲存
                </IonButton>
              </IonButtons>
            }
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="inputform">
          {this.state.pageData &&
            <React.Fragment>
              <div className="titlebar">
                <h3>派工意願表</h3>
                <div className="note">
                  <span>*</span>為必填
                </div>
              </div>
              <form ref="Form" noValidate>
                <IonList>
                  {/*<IonItem>
                  <IonLabel position="stacked">培訓結束後邀約對象情況 <span>*</span></IonLabel>
                </IonItem>
                <IonItem>


                  <IonCheckbox slot="start"
                               name="Is已邀約到一對一對象"
                               value={this.state.Item_會員.Is已邀約到一對一對象}
                               checked={this.state.Item_會員.Is已邀約到一對一對象}
                               onIonChange={(e: any) => this.handleInputCheckedChange(e)}
                  />
                  <IonLabel>已邀約到一對一對象</IonLabel>
                </IonItem>

                {this.state.Item_會員.Is已邀約到一對一對象 === true &&
                <IonItem>
                  <IonLabel position="stacked">對象的信仰狀態</IonLabel>
                  <IonSelect name="已邀約到一對一對象_信仰狀態"
                             value={this.state.Item_會員.已邀約到一對一對象_信仰狀態}
                             onIonChange={(e: any) => this.handleInputChange(e)}
                             interfaceOptions={customActionSheetOptions1}
                             interface="action-sheet" placeholder="請選擇" okText="確認" cancelText="取消">
                    <IonSelectOption value="未信者">未信者</IonSelectOption>
                    <IonSelectOption value="基督徒">基督徒</IonSelectOption>
                    <IonSelectOption value="基督徒(不穩)信仰不清">基督徒(不穩)信仰不清</IonSelectOption>
                    <IonSelectOption value="其他">其他</IonSelectOption>
                  </IonSelect>
                </IonItem>
                }


                {this.state.Item_會員.已邀約到一對一對象_信仰狀態 === "其他" &&
                <IonItem>
                  <IonInput name="已邀約到一對一對象_信仰狀態_其他"
                            value={this.state.Item_會員.已邀約到一對一對象_信仰狀態_其他}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="text"
                            placeholder="請輸入對象信仰"></IonInput>
                </IonItem>
                }

                {this.state.Item_會員.Is已邀約到一對一對象 === true &&
                <IonItem>
                  <IonLabel position="stacked">預計何時開始一對一</IonLabel>
                  <IonInput name="已邀約到一對一對象_預計何時開始一對一"
                            value={this.state.Item_會員.已邀約到一對一對象_預計何時開始一對一}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="text"
                            placeholder="請輸入時間，例：2020年1月"></IonInput>
                </IonItem>
                }
                <IonItem>
                  <IonCheckbox
                    value={this.state.Item_會員.Is已有預備邀約對象}
                    checked={this.state.Item_會員.Is已有預備邀約對象}
                    name="Is已有預備邀約對象"
                    onIonChange={(e: any) => this.handleInputCheckedChange(e)}
                    slot="start"
                  />
                  <IonLabel>已有預備邀約的對象</IonLabel>
                </IonItem>
                {this.state.Item_會員.Is已有預備邀約對象 === true &&
                <IonItem>
                  <IonLabel position="stacked">預備邀約對象的姓名</IonLabel>
                  <IonInput name="已有預備邀約對象_姓名"
                            value={this.state.Item_會員.已有預備邀約對象_姓名}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="text"
                            placeholder="請輸入姓名"></IonInput>
                </IonItem>
                }
                <IonItem>
                  <IonCheckbox slot="start"
                               name="Is需要教會提供對象"
                               value={this.state.Item_會員.Is需要教會提供對象}
                               checked={this.state.Item_會員.Is需要教會提供對象}
                               onIonChange={(e: any) => this.handleInputCheckedChange(e)}
                  />
                  <IonLabel>需要教會協助提供對象</IonLabel>
                </IonItem>
                <IonItem>
                  <IonCheckbox
                    slot="start"
                    name="Is目前尚不想開始邀約"
                    value={this.state.Item_會員.Is目前尚不想開始邀約}
                    checked={this.state.Item_會員.Is目前尚不想開始邀約}
                    onIonChange={(e: any) => this.handleInputCheckedChange(e)}
                  />
                  <IonLabel>目前尚不想開始邀約</IonLabel>
                </IonItem>
                {this.state.Item_會員.Is目前尚不想開始邀約 === true &&
                <IonItem>
                  <IonLabel position="stacked">不想邀約原因</IonLabel>
                  <IonInput name="目前尚不想開始邀約_原因"
                            value={this.state.Item_會員.目前尚不想開始邀約_原因}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="text"
                            placeholder="請輸入原因"></IonInput>
                </IonItem>
                }
              */}

                  <IonItem>
                    <IonCheckbox slot="start"
                                 name="派工意願"
                                 value={this.state.Item_會員.派工意願}
                                 checked={this.state.Item_會員.派工意願 == "可接受派工"}
                                 onIonChange={(e: any) => this.handleInputCheckedChangeVal(e, "派工意願", "可接受派工")}
                    />
                    <IonLabel>可接受派工</IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonCheckbox slot="start"
                                 name="派工意願"
                                 value={this.state.Item_會員.派工意願}
                                 checked={this.state.Item_會員.派工意願 == "暫時無法接受派工"}
                                 onIonChange={(e: any) => this.handleInputCheckedChangeVal(e, "派工意願", "暫時無法接受派工")}
                    />
                    <IonLabel>暫時無法接受派工</IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonCheckbox slot="start"
                                 name="派工意願"
                                 value={this.state.Item_會員.派工意願}
                                 checked={this.state.Item_會員.派工意願 == "無法接受派工"}
                                 onIonChange={(e: any) => this.handleInputCheckedChangeVal(e, "派工意願", "無法接受派工")}
                    />
                    <IonLabel>無法接受派工</IonLabel>
                  </IonItem>

                  {/*<IonRadioGroup allowEmptySelection={true}*/}
                  {/*               name="派工意願"*/}
                  {/*               value={this.state.Item_會員.派工意願}*/}
                  {/*               onIonChange={(e: any) => this.handleInputCheckedChange(e)}*/}
                  {/*>*/}
                  {/*  <IonListHeader>*/}
                  {/*    <IonLabel>接受派工的意願 <span>*</span></IonLabel>*/}
                  {/*  </IonListHeader>*/}


                  {/*  <IonItem>*/}
                  {/*    <IonLabel>可接受派工</IonLabel>*/}
                  {/*    <IonRadio slot="strat" value="可接受派工"/>*/}
                  {/*  </IonItem>*/}

                  {/*  <IonItem>*/}
                  {/*    <IonLabel>暫時無法接受派工</IonLabel>*/}
                  {/*    <IonRadio slot="strat" value="暫時無法接受派工"/>*/}
                  {/*  </IonItem>*/}

                  {/*  <IonItem>*/}
                  {/*    <IonLabel>無法接受派工</IonLabel>*/}
                  {/*    <IonRadio slot="strat" value="無法接受派工"/>*/}
                  {/*  </IonItem>*/}
                  {/*</IonRadioGroup>*/}

                  {(this.state.Item_會員.派工意願 === "暫時無法接受派工" || this.state.Item_會員.派工意願 === "無法接受派工") &&
                    <IonItem>
                      <IonLabel position="stacked">無法接受原因 </IonLabel>
                      <IonInput name="派工意願_原因"
                                value={this.state.Item_會員.派工意願_原因}
                                onIonChange={(e: any) => this.handleInputChange(e)}
                                type="text"
                                placeholder="請輸入原因"></IonInput>
                    </IonItem>
                  }
                  {this.state.Item_會員.派工意願 === "可接受派工" &&
                    <div className="recivetimetable">
                      <div className="th">派工時段</div>
                      <div className="timetable">
                        <div className="tb tbheader">
                          <div className="tb_th">時間</div>
                          <div className="tb_th">一</div>
                          <div className="tb_th">二</div>
                          <div className="tb_th">三</div>
                          <div className="tb_th">四</div>
                          <div className="tb_th">五</div>
                          <div className="tb_th">六</div>
                          <div className="tb_th">日</div>
                        </div>
                        <div className="tb">
                          <div className="tb_td tb_th">上午</div>
                          <div className="tb_td"><IonCheckbox name="派工時段_一-上午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_一-上午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_一-上午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_二-上午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_二-上午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_二-上午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_三-上午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_三-上午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_三-上午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_四-上午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_四-上午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_四-上午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_五-上午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_五-上午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_五-上午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_六-上午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_六-上午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_六-上午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_日-上午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_日-上午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_日-上午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                        </div>
                        <div className="tb">
                          <div className="tb_td tb_th">下午</div>
                          <div className="tb_td"><IonCheckbox name="派工時段_一-下午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_一-下午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_一-下午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_二-下午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_二-下午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_二-下午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_三-下午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_三-下午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_三-下午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_四-下午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_四-下午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_四-下午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_五-下午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_五-下午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_五-下午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_六-下午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_六-下午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_六-下午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_日-下午"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_日-下午`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_日-下午`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                        </div>
                        <div className="tb">
                          <div className="tb_td tb_th">晚上</div>
                          <div className="tb_td"><IonCheckbox name="派工時段_一-晚上"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_一-晚上`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_一-晚上`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_二-晚上"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_二-晚上`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_二-晚上`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_三-晚上"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_三-晚上`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_三-晚上`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_四-晚上"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_四-晚上`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_四-晚上`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_五-晚上"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_五-晚上`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_五-晚上`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_六-晚上"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_六-晚上`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_六-晚上`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                          <div className="tb_td"><IonCheckbox name="派工時段_日-晚上"
                                                              value={this.state.Dict_會員_派工時段[`派工時段_日-晚上`]}
                                                              checked={this.state.Dict_會員_派工時段[`派工時段_日-晚上`]}
                                                              onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                        </div>
                      </div>
                    </div>
                  }


                  {/*<IonItem>
                  <IonLabel position="stacked">接受派工的意願 <span>*</span></IonLabel>
                  <IonSelect name="派工意願"
                             value={this.state.Item_會員.派工意願}
                             onIonChange={(e: any) => this.handleInputChange(e)}
                             interfaceOptions={customActionSheetOptions1}
                             interface="action-sheet" placeholder="請選擇" okText="確認" cancelText="取消">
                    <IonSelectOption value="可接受派工">可接受派工</IonSelectOption>
                    <IonSelectOption value="暫時無法接受派工">暫時無法接受派工</IonSelectOption>
                    <IonSelectOption value="無法接受派工">無法接受派工</IonSelectOption>
                  </IonSelect>

                </IonItem>
                {(this.state.Item_會員.派工意願 === "暫時無法接受派工" || this.state.Item_會員.派工意願 === "無法接受派工") &&
                <IonItem>
                  <IonLabel position="stacked">無法接受原因 </IonLabel>
                  <IonInput name="派工意願_原因"
                            value={this.state.Item_會員.派工意願_原因}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="text"
                            placeholder="請輸入原因"></IonInput>
                </IonItem>
                }
                {this.state.Item_會員.派工意願 === "可接受派工" &&
                <div className="recivetimetable">
                  <div className="th">派工時段</div>
                  <div className="timetable">
                    <div className="tb tbheader">
                      <div className="tb_th">時間</div>
                      <div className="tb_th">一</div>
                      <div className="tb_th">二</div>
                      <div className="tb_th">三</div>
                      <div className="tb_th">四</div>
                      <div className="tb_th">五</div>
                      <div className="tb_th">六</div>
                      <div className="tb_th">日</div>
                    </div>
                    <div className="tb">
                      <div className="tb_td tb_th">上午</div>
                      <div className="tb_td"><IonCheckbox name="派工時段_一-上午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_一-上午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_一-上午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_二-上午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_二-上午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_二-上午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_三-上午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_三-上午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_三-上午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_四-上午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_四-上午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_四-上午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_五-上午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_五-上午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_五-上午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_六-上午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_六-上午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_六-上午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_日-上午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_日-上午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_日-上午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                    </div>
                    <div className="tb">
                      <div className="tb_td tb_th">下午</div>
                      <div className="tb_td"><IonCheckbox name="派工時段_一-下午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_一-下午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_一-下午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_二-下午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_二-下午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_二-下午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_三-下午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_三-下午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_三-下午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_四-下午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_四-下午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_四-下午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_五-下午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_五-下午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_五-下午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_六-下午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_六-下午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_六-下午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_日-下午"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_日-下午`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_日-下午`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                    </div>
                    <div className="tb">
                      <div className="tb_td tb_th">晚上</div>
                      <div className="tb_td"><IonCheckbox name="派工時段_一-晚上"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_一-晚上`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_一-晚上`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_二-晚上"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_二-晚上`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_二-晚上`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_三-晚上"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_三-晚上`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_三-晚上`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_四-晚上"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_四-晚上`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_四-晚上`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_五-晚上"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_五-晚上`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_五-晚上`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_六-晚上"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_六-晚上`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_六-晚上`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                      <div className="tb_td"><IonCheckbox name="派工時段_日-晚上"
                                                          value={this.state.Dict_會員_派工時段[`派工時段_日-晚上`]}
                                                          checked={this.state.Dict_會員_派工時段[`派工時段_日-晚上`]}
                                                          onIonChange={(e: any) => this.handleTimeDictChange(e)}/></div>
                    </div>
                  </div>
                </div>
                }*/}
                </IonList>

                <div className="titlebar">
                  <h3>個人資料</h3>
                  <div className="note">
                    <span>*</span>為必填
                  </div>
                </div>

                <IonList>
                  <IonItem>
                    <IonLabel position="stacked">姓氏 <span>*</span></IonLabel>
                    <IonInput name="姓氏"
                              value={this.state.Item_會員.姓氏}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text" required
                              placeholder="請輸入姓名"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">名字 <span>*</span></IonLabel>
                    <IonInput name="名字"
                              value={this.state.Item_會員.名字}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text" required
                              placeholder="請輸入姓名"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">性別 <span>*</span></IonLabel>
                    <IonSelect name="性別"
                               value={this.state.Item_會員.性別}
                               onIonChange={(e: any) => this.handleInputChange(e)}
                               interface="action-sheet"
                               placeholder="請選擇"
                               okText="確認" cancelText="取消">
                      <IonSelectOption value="女性">女性</IonSelectOption>
                      <IonSelectOption value="男性">男性</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">出生年份 <span>*</span></IonLabel>

                    <IonDatetime
                      name="出生年月日"
                      value={this.state.Item_會員.出生年月日}
                      onIonChange={(e: any) => this.handleInputChange(e)}
                      min="1911-01"
                      max={usebornyear}
                      displayFormat="YYYY/MM/DD" placeholder="請選擇日期" done-text="確定" cancel-text="取消"></IonDatetime>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">手機 <span>*</span></IonLabel>
                    <IonInput name="手機"
                              value={this.state.Item_會員.手機}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text" required
                              placeholder="請輸入手機號碼"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Email <span>*</span></IonLabel>
                    <IonInput name="Email"
                              value={this.state.Item_會員.Email}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入Email" disabled></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">學歷</IonLabel>
                    <IonSelect name="學歷"
                               value={this.state.Item_會員.學歷}
                               onIonChange={(e: any) => this.handleInputChange(e)}
                               interface="action-sheet"
                               placeholder="請選擇"
                               okText="確認" cancelText="取消">
                      <IonSelectOption value="小學">小學</IonSelectOption>
                      <IonSelectOption value="國中">國中</IonSelectOption>
                      <IonSelectOption value="高中">高中</IonSelectOption>
                      <IonSelectOption value="專科">專科</IonSelectOption>
                      <IonSelectOption value="大學">大學</IonSelectOption>
                      <IonSelectOption value="碩士">碩士</IonSelectOption>
                      <IonSelectOption value="博士">博士</IonSelectOption>
                      <IonSelectOption value="其他">其他</IonSelectOption>
                    </IonSelect>
                    {this.state.Item_會員.學歷 === "其他" &&
                      <IonInput name="學歷_其他"
                                value={this.state.Item_會員.學歷}
                                type="text" required
                                placeholder="請輸入其他學歷"></IonInput>
                    }
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">職業</IonLabel>
                    <IonSelect name="職業"
                               value={this.state.Item_會員.職業}
                               onIonChange={(e: any) => this.handleInputChange(e)}
                               interface="action-sheet"
                               placeholder="請選擇"
                               okText="確認" cancelText="取消">
                      <IonSelectOption value="企業負責人">企業負責人</IonSelectOption>
                      <IonSelectOption value="公司主管">公司主管</IonSelectOption>
                      <IonSelectOption value="上班族">上班族</IonSelectOption>
                      <IonSelectOption value="服務業">服務業</IonSelectOption>
                      <IonSelectOption value="學生">學生</IonSelectOption>
                      <IonSelectOption value="家管">家管</IonSelectOption>
                      <IonSelectOption value="退休人員">退休人員</IonSelectOption>
                      <IonSelectOption value="其他">其他</IonSelectOption>
                    </IonSelect>
                    {this.state.Item_會員.職業 === "其他" &&
                      <IonInput name="職業_其他"
                                value={this.state.Item_會員.職業}
                                onIonChange={(e: any) => this.handleInputChange(e)}
                                type="text"
                                placeholder="請輸入職業"></IonInput>
                    }
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">使用語言</IonLabel>
                  </IonItem>
                  <IonItem>
                    {/*＊＊＊＊＊＊＊＊＊＊語言為複選＊＊＊＊＊＊＊＊＊＊＊＊＊*/}
                    <IonCheckbox
                      slot="start"
                      name="中文"
                      value={this.state.Dict_會員_語言[`中文`]}
                      checked={this.state.Dict_會員_語言[`中文`]}
                      onIonChange={(e: any) => this.handleLangDictChange(e)}
                    />
                    <IonLabel>中文</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      name="English"
                      value={this.state.Dict_會員_語言[`English`]}
                      checked={this.state.Dict_會員_語言[`English`]}
                      onIonChange={(e: any) => this.handleLangDictChange(e)}
                    />
                    <IonLabel>English</IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">信主年份 <span>*</span></IonLabel>
                    <IonInput name="信主年數"
                              value={this.state.Item_會員.信主年數}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入信主年數 ex:5"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">所屬教會</IonLabel>
                    <IonInput name="所屬教會"
                              value={this.state.Item_會員.所屬教會}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入所屬教會"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">所屬教會國家</IonLabel>
                    <IonInput name="所屬教會國家"
                              value={this.state.Item_會員.所屬教會國家}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入所屬教會國家"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">所屬教會城市</IonLabel>
                    <IonInput name="所屬教會城市"
                              value={this.state.Item_會員.所屬教會城市}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入所屬教會城市"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">所屬教會行政區</IonLabel>
                    <IonInput name="所屬教會行政區"
                              value={this.state.Item_會員.所屬教會行政區}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入所屬教會行政區"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">服事職務</IonLabel>
                    <IonInput name="服事職務"
                              value={this.state.Item_會員.服事職務}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入服事職稱"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">區名稱</IonLabel>
                    <IonInput name="區名"
                              value={this.state.Item_會員.區名}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入區名稱"></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">小組名稱</IonLabel>
                    <IonInput name="團契小組"
                              value={this.state.Item_會員.團契小組}
                              onIonChange={(e: any) => this.handleInputChange(e)}
                              type="text"
                              placeholder="請輸入小組名稱"></IonInput>
                  </IonItem>
                </IonList>


                {/*<div className="titlebar">
                <h3>密碼更改</h3>
              </div>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">密碼</IonLabel>
                  <IonInput name="密碼"
                            value={this.state.Item_會員.密碼}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="password" required
                            placeholder="請輸入密碼"></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">密碼確認（需再度確認後“儲存”才會生效）</IonLabel>
                  <IonInput name="密碼_check"
                            value={this.state.Item_會員.密碼_check}
                            onIonChange={(e: any) => this.handleInputChange(e)}
                            type="password" required
                            placeholder="請再次確認密碼"></IonInput>
                </IonItem>
                </IonList>*/}
              </form>
            </React.Fragment>}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
});

export default connect(
  mapStateToProps
)(Personaldataedit);
