import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent
} from '@ionic/react';
import './Curriculum.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, cloudDownload, book, checkmark, addCircleOutline, settings, checkmarkCircleOutline, folder} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {AuthComponent} from "./_/AuthComponent";
import Moment from 'react-moment';
import {StaticEvent} from "../_/StaticEvent";
import ReactHtmlParser from 'react-html-parser';

var moment = require('moment');

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}

function styleNameList(nameList:string, className: string = null) {
  if (nameList == null || nameList == '')
    return;

  var output = (nameList).split(',');
  var output_with_qoutes = null;

  if (className === null) {
    output_with_qoutes = output.map((r) => {
      return `<span>${r}</span>`
    });
  } else {
    output_with_qoutes = output.map((r) => {
      return `<span class="${className}">${r}</span>`
    });
  }
  return ReactHtmlParser(output_with_qoutes.join(''));
}

class Curriculum_tutorial extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/curriculum');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  async handleDeleteSubmit(e: any) {
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_curriculummanagement_delete');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.props.history.push('/curriculumlist_tutorial');

          } else {
            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    let Content: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');


    if (pageData != null) {
      let its = pageData.List_對課進程;
      let Item_對課 = pageData.Item_對課;

      if (its === null) {
        alert('嚴重錯誤');
        return;
      }


      Content = <>
        <div className="pattern_title">
          {styleNameList(Item_對課._對象姓名_完結, 'started')}
          {styleNameList(Item_對課._對象姓名_未完結, 'halt')}
          {/* <IonIcon icon={folder} color="black"  slot="start" ></IonIcon> {Item_對課.案件名稱} <span>(資料夾)</span> */}
          </div>
        <div className='instructions'>
          <ol>
            <li>請依課程次序下載</li>
            <li>課程簡介/第１課/第2課，此三課於課後即可下載，供工人前三週對課使用</li>
            <li>「之後的課程」工人將於對課前一週填寫將要對課的日期方可下載該課「逐字講稿」</li>
            <li>單次對完課程後，務必回來點入該課程，然後去勾選<IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>「完成對課」，以便保有完整對課記錄</li>
          </ol>
        </div>
        <IonList>
          {its.map(it => {

            let isComplete = it.完成對課;
            let lessionName = _.custom.lessionName(it.第幾課);

            let icon = null;


            let itemProps: any = {};

            if (isComplete) {
              icon = <IonIcon icon={checkmark} color="success" slot="start"></IonIcon>;
              itemProps.button = true;
              itemProps.onClick = (
                (e) => {
                  e.preventDefault();
                  this.props.history.push(`/curriculumsituationinput_tutorial/${it.對課進程Id}`);
                });
            } else {
              if (it.下載逐字稿_開放時間 && (moment() >= moment(it.下載逐字稿_開放時間))) {

                //第一次對課填表, 要求指定填寫 預計對課日期
                if (!it.上課時間) {
                  icon = <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>;
                  itemProps.button = true;
                  itemProps.onClick = (
                    (e) => {
                      e.preventDefault();
                      this.props.history.push(`/curriculumsituationinput_tutorial_date/${it.對課進程Id}`);
                    });
                } else {
                  //之前已填完"預計對課日期"，打開內容
                  icon = <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>;
                  itemProps.button = true;
                  itemProps.onClick = (
                    (e) => {
                      e.preventDefault();
                      this.props.history.push(`/curriculumsituationinput_tutorial/${it.對課進程Id}`);
                    });
                }
              } else {
                if (it.下載逐字稿_開放時間 && (moment() < moment(it.下載逐字稿_開放時間))) {
                  itemProps.onClick = (
                    (e) => {
                      e.preventDefault();
                      alert('課程開放時間還未到');
                    });
                } else {
                  itemProps.onClick = (
                    (e) => {
                      e.preventDefault();
                      alert('前一課需要完成對課');
                    });
                }
                icon = <IonIcon src="/assets/icon/closebook.svg" slot="start"></IonIcon>;
              }
            }

            return <IonItem
              {...itemProps}
              key={it.對課進程Id}>
              {icon}
              <IonLabel>
                <div className="crrpj">
                  <h3>{lessionName}</h3>
                  <div className="crrdetail">
                    {/* {it.上課時間 ? (<div className="crrdate"><Moment format="YYYY/MM/DD" date={it.上課時間}/> 下載逐字稿</div>):(<span className="pretext">下載逐字稿＆填寫對課紀錄</span>)} */}
                    {it.上課時間 ? (<div className="crrdate">完成對課/反饋/代禱</div>) : (<span className="pretext">下載逐字稿・課後填寫對課紀錄</span>)}
                    <p></p>
                  </div>
                </div>
              </IonLabel>
            </IonItem>
          })}
          {/*  */}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon icon={checkmark} color="success" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第1課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第2課</h3>*/}
          {/*        /!*<div className="crrdetail">*/}
          {/*            <div className="crrdate">*/}
          {/*              2019/10/24*/}
          {/*            </div>*/}
          {/*            <p>備註備註備註備註備註備註備註備註備註備註備註</p>*/}
          {/*        </div>*!/*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第3課</h3>*/}
          {/*        /!*<div className="crrdetail">*/}
          {/*            <div className="crrdate">*/}
          {/*              2019/10/24*/}
          {/*            </div>*/}
          {/*            <p>備註備註備註備註備註備註備註備註備註備註備註</p>*/}
          {/*        </div>*!/*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第4課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第5課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第6課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第7課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第8課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第9課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第10課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
        </IonList>
      </>;

    }


    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
                // if (pageData && pageData.Item_對課) {
                //   this.props.history.replace(`/curriculummanagement_tutorial/${pageData.Item_對課.對課Id}`);
                // } else {
                //   this.props.history.goBack();
                // }
              }} text="返回"/>
            </IonButtons>
            <IonTitle>對課紀錄</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="currma slideroption">
          {Content}

          {/*<div className="noteddetail">
            當您已完整對完第一個對象，手中已有 0～10 課逐字講稿，未來若需要再度下載：可從“首頁”右上角點擊設定
            <IonIcon icon={settings}></IonIcon> 然後選擇教材下載
            </div>*/}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Curriculum_tutorial);
