import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter
} from '@ionic/react';
import './Studentdatainputstep2.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent
}

const customActionSheetOptions1 = {
  header: '請選擇對象信仰背景',
};
const customActionSheetOptions2 = {
  header: '請選擇對象信仰狀況',
};

class Studentdatainputstep2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null
    };
  }

  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={() => this.props.history.goBack()} text="返回"/>
            </IonButtons>
            <IonTitle>新增對象</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div className="titlebar">
            <h3>信仰狀態</h3>
          </div>
          <form noValidate>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">課前信仰背景 <span>*</span></IonLabel>
                <IonSelect interfaceOptions={customActionSheetOptions1}
                           interface="action-sheet" placeholder="請選擇" okText="確認" cancelText="取消">
                  <IonSelectOption value="">基督徒</IonSelectOption>
                  <IonSelectOption value="">慕道友</IonSelectOption>
                  <IonSelectOption value="">無任何信仰</IonSelectOption>
                  <IonSelectOption value="">其他宗教信仰</IonSelectOption>
                </IonSelect>
                {/*<IonInput name="" type="text" required placeholder="請輸入其他宗教名稱"></IonInput>*/}
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">課前信仰狀況 <span>*</span></IonLabel>
                <IonSelect interfaceOptions={customActionSheetOptions2} interface="action-sheet"
                           placeholder="請選擇基督徒課前信仰狀況" okText="確認" cancelText="取消">
                  <IonSelectOption value="">穩定聚會-自我操練</IonSelectOption>
                  <IonSelectOption value="">穩定聚會-已決志未受洗</IonSelectOption>
                  <IonSelectOption value="">不穩定聚會-已決志</IonSelectOption>
                  <IonSelectOption value="">不穩定聚會-已受洗</IonSelectOption>
                  <IonSelectOption value="">不穩定聚會-遠離教會</IonSelectOption>
                </IonSelect>
                {/*<IonInput name="" type="text" required placeholder="請輸入慕道多少年"></IonInput>*/}
              </IonItem>
            </IonList>
          </form>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton expand="block" color="primary" type="submit" onClick={
              (e) => {
                e.preventDefault();
                this.props.history.push('/studentdatainputstep3');
              }}>
              下一步
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Studentdatainputstep2);
