import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonCard,
  IonSegmentButton,
  IonAlert,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent
} from '@ionic/react';
import './Curriculum.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin, cloudDownload, book, checkmark, addCircleOutline, settings, checkmarkCircleOutline} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import {AuthComponent} from "./_/AuthComponent";
import Moment from 'react-moment';
import {StaticEvent} from "../_/StaticEvent";

var moment = require('moment');

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}

class Curriculum extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/curriculum');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              window.location.href = '/login';
              //this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }

  async handleDeleteSubmit(e: any) {
    // @ts-ignore
    const id = this.props.match.params.id;
    if (!id) {
      return; //ERROR INPUTS
    }

    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      p1: id,
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/_curriculummanagement_delete');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            this.props.history.push('/curriculumlist');

          } else {
            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  }

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  }

  render() {
    let Content: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');


    if (pageData != null) {
      var its = pageData.List_對課進程;

      if (its === null) {
        alert('嚴重錯誤');
        return;
      }


      Content = <>
        <IonList>
          {its.map(it => {

            let isComplete = it.完成對課;
            let lessionName = _.custom.lessionName(it.第幾課);

            let icon = null;

            let lessonStatus:String = '';


            let itemProps: any = {};

            if (isComplete) {
              icon = <IonIcon icon={checkmark} color="success" slot="start"></IonIcon>;
              lessonStatus = "完成對課";
              itemProps.button = true;
              itemProps.onClick = (
                (e) => {
                  e.preventDefault();
                  this.props.history.push(`/curriculumsituationinput/${it.對課進程Id}`);
                });
            } else {
              if (it.下載逐字稿_開放時間 && (moment() >= moment(it.下載逐字稿_開放時間))) {
                icon = <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>;
                lessonStatus = "預計對課";
                itemProps.button = true;
                itemProps.onClick = (
                  (e) => {
                    e.preventDefault();
                    this.props.history.push(`/curriculumsituationinput/${it.對課進程Id}`);
                  });
              } else {
                if (it.下載逐字稿_開放時間 && (moment() < moment(it.下載逐字稿_開放時間))) {
                  itemProps.onClick = (
                    (e) => {
                      e.preventDefault();
                      alert('課程開放時間還未到');
                    });
                } else {
                  itemProps.onClick = (
                    (e) => {
                      e.preventDefault();
                      alert('前一課需要完成對課');
                    });
                }
                icon = <IonIcon src="/assets/icon/closebook.svg" slot="start"></IonIcon>;
              }
            }

            return <IonItem
                            {...itemProps}
                            key={it.對課進程Id}>
              {icon}
              <IonLabel>
                <div className="crrpj">
                  <h3>{lessionName}</h3>
                  <div className="crrdetail">
                    
                    {it.上課時間 ? (<div className="crrdate"><Moment format="YYYY/MM/DD" date={it.上課時間}/>  {lessonStatus}</div>):(<span className="pretext">填寫對課紀錄</span>)}
                    <p></p>
                  </div>
                </div>
              </IonLabel>
            </IonItem>
          })}
          {/*  */}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon icon={checkmark} color="success" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第1課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第2課</h3>*/}
          {/*        /!*<div className="crrdetail">*/}
          {/*            <div className="crrdate">*/}
          {/*              2019/10/24*/}
          {/*            </div>*/}
          {/*            <p>備註備註備註備註備註備註備註備註備註備註備註</p>*/}
          {/*        </div>*!/*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第3課</h3>*/}
          {/*        /!*<div className="crrdetail">*/}
          {/*            <div className="crrdate">*/}
          {/*              2019/10/24*/}
          {/*            </div>*/}
          {/*            <p>備註備註備註備註備註備註備註備註備註備註備註</p>*/}
          {/*        </div>*!/*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第4課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第5課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第6課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第7課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第8課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第9課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
          {/*  <IonItem button*/}
          {/*           href="/curriculumsituationinput" onClick={*/}
          {/*    (e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      this.props.history.push('/curriculumsituationinput');*/}
          {/*    }}>*/}
          {/*    <IonIcon src="/assets/icon/open-bookss.svg" slot="start"></IonIcon>*/}
          {/*    <IonLabel>*/}
          {/*      <div className="crrpj">*/}
          {/*        <h3>第10課</h3>*/}
          {/*        <div className="crrdetail">*/}
          {/*          <div className="crrdate">*/}

          {/*          </div>*/}
          {/*          <p></p>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </IonLabel>*/}
          {/*  </IonItem>*/}
        </IonList>
      </>;

    }


    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
            <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
                // if (pageData && pageData.Item_對課) {
                //   this.props.history.replace(`/curriculummanagement/${pageData.Item_對課.對課Id}`);
                // } else {
                //   this.props.history.goBack();
                // }
              }} text="返回"/>
            </IonButtons>
            <IonTitle>對課紀錄</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.showPopoverEvent}
          onDidDismiss={this.dismissPopover}
        >
          <AboutPopover
            dismissPopover={this.dismissPopover}
          />
        </IonPopover>

        <IonContent className="currma slideroption">
        <div className='instructions'>
            <ul>
              <li>為保有“完整對課記錄” 請於單次對完課後，記得點選“該課”進入勾選<IonIcon icon={checkmarkCircleOutline} slot="start"></IonIcon>「此課對課完打勾」</li>
            </ul>
          </div>
          {Content}

          {/*<div className="noteddetail">
            當您已完整對完第一個對象，手中已有 0～10 課逐字講稿，未來若需要再度下載：可從“首頁”右上角點擊設定
            <IonIcon icon={settings}></IonIcon> 然後選擇教材下載
            </div>*/}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Curriculum);
