import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RootState, selectors} from '../store';
import {
  IonPopover,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTitle,
  IonBackButton,
  IonInput,
  IonRow,
  IonCol,
  IonPage,
  IonFooter,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonAlert, IonItemSliding, IonItemOptions, IonItemOption
} from '@ionic/react';
import './Root.css';
import AboutPopover from '../components/AboutPopover';
import {calendar, pin} from 'ionicons/icons';
import {RouteComponentProps} from "react-router";
import {AuthComponent} from "./_/AuthComponent";
import {StaticEvent} from "../_/StaticEvent";
import {ApiService} from "../_/ApiService";
import {Static} from "../_/Static";
import _ from "../_";
import Moment from "react-moment";

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>

type State = {
  showPopover: boolean,
  showPopoverEvent: null | MouseEvent,

  _userData: any,
  pageData: any,

  shouldLoadPageDataAgain: boolean,
  pagePathname: string,
}

class Personalstatistics extends AuthComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopover: false,
      showPopoverEvent: null,

      _userData: null,
      pageData: null,

      shouldLoadPageDataAgain: false,
      pagePathname: this.props.location.pathname,
    };
  }


  async componentDidMount() {
    await super.componentDidMount();

    //<editor-fold desc="Init (Once only)" defaultstate="collapsed" >
    if (this._doInitInDidMount === true) {
      this._doInitInDidMount = false;  // make it once only

      // window.$ = jQuery;
      // jQuery('[data-my-script]').each(function forEachScript() {
      //     const script = jQuery(this).text();
      //     // @ts-ignore
      //     window.eval(script);
      // });
      //
    }
    //</editor-fold>

    //<editor-fold desc="Loading PageData Or EXIT" defaultstate="collapsed" >
    if (this._doLoadingPageDataInDidMount) {
      await this._loadPageData();
    } else {
      return;
    }
    //</editor-fold>

    // @ts-ignore
    this.setState({
      _userData: this._userData,
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const locationChanged = this.props.location !== prevProps.location;

    //console.error(this.props.location);

    // if (locationChanged || this.state.shouldLoadPageDataAgain) {
    //   if (this.state.shouldLoadPageDataAgain !== false) {
    //     await this.setState({shouldLoadPageDataAgain: false});
    //   }
    //   await this._loadPageData();
    // }
    //
    // && this.props.location.pathname == this.state.pagePathname
    if (locationChanged) {
      //await this._loadPageData();
      await this.componentDidMount();
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();

    this.subscribed.forEach((v, i) => {
      v.unsubscribe();
    });
    this.subscribed = [];
  }

  async _loadPageData() {
    // VALIDATE INPUT
    // @ts-ignore
    // const id = this.props.match.params.id;
    // if (!id) {
    //   return; //ERROR INPUTS
    // }
    //
    // WAITING SCREEN
    StaticEvent.LoadingEvent.next(true);

    // POST
    let postData = {
      timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
    };
    const postUrl = ApiService.urlMapping('~/api/1/personalstatistics');
    Static.apiService
      .post(postUrl, postData)
      .subscribe(async (res: any) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);

          let data = res.data;
          _.debugManager.log(data);

          let error = data.error || null;
          let result = error == null;

          if (result) { // SUCEESS

            let payload = data.payload;
            _.debugManager.log(payload);

            // @ts-ignore
            await this.setState({
              pageData: payload,
            });

          } else {
            // AUTH USER EXPIRED
            if (error.code == 2) {
              alert('帳號被登出，請重新登入');
              this.props.history.push('/login')
              return;
            }

            alert(error.message);
          }
        }, async (err) => {
          // CLOSE WAITING SCREEN
          StaticEvent.LoadingEvent.next(false);
          _.debugManager.warn(err);
          alert('網路連線有問題');
        }
      );
  }


  presentPopover = (e: MouseEvent) => {
    this.setState(() => ({
      showPopover: true,
      showPopoverEvent: e
    }));
  };

  dismissPopover = () => {
    this.setState(() => ({
      'showPopover': false,
      'showPopoverEvent': null
    }));
  };

  render() {

    let Content: any = null;
    let pageData = _.utilManager.existGet(this.state, 'pageData');

    if (pageData !== null) {



      Content =
      <IonContent className="index-page">
        <IonList lines="full">
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>目前對課人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.目前對課人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>對完十課人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.對課完成人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>課前未信者人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.未信者人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>課前不穩定基督徒人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.不穩定聚會基督徒人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>領決志人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.決志人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>領受洗人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.受洗人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>領進教會人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.進教會人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          {/*<IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>課程中止人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.課程中止人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
          <IonItem className="useritembar">
            <IonLabel>
              <div className="useritem">
                <h3>對課總人數</h3>
                <div className="userinfodetail">
                  <span className="ppamount">{this.state.pageData.Item_STATE.對課總人數}</span>
                </div>
              </div>
            </IonLabel>
          </IonItem>
        */}


        </IonList>
      </IonContent>
    } else {
      Content = null;
    }

    return (
      <>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.goBack();
              }} text="返回"/>
            </IonButtons>
            <IonTitle>對課人數</IonTitle>
          </IonToolbar>
        </IonHeader>
        {Content}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  conferenceDate: selectors.sessions.conferenceStart(state.sessions),
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps
)(Personalstatistics);
